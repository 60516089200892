/**火星坐标系 (GCJ-02) 与百度坐标系 (BD-09) 的互转
 * Created by macremote on 16/5/3.
 */

const pi = 3.1415926535897932384626;
const x_pi = (3.14159265358979324 * 3000.0) / 180.0;
const a = 6378245.0;
const ee = 0.00669342162296594323;

class GPSUtil {
  private transformLat(x: number, y: number) {
    var ret =
      -100.0 +
      2.0 * x +
      3.0 * y +
      0.2 * y * y +
      0.1 * x * y +
      0.2 * Math.sqrt(Math.abs(x));
    ret +=
      ((20.0 * Math.sin(6.0 * x * pi) + 20.0 * Math.sin(2.0 * x * pi)) * 2.0) /
      3.0;
    ret +=
      ((20.0 * Math.sin(y * pi) + 40.0 * Math.sin((y / 3.0) * pi)) * 2.0) / 3.0;
    ret +=
      ((160.0 * Math.sin((y / 12.0) * pi) + 320 * Math.sin((y * pi) / 30.0)) *
        2.0) /
      3.0;
    return ret;
  }

  private transformLon(x: number, y: number) {
    var ret =
      300.0 +
      x +
      2.0 * y +
      0.1 * x * x +
      0.1 * x * y +
      0.1 * Math.sqrt(Math.abs(x));
    ret +=
      ((20.0 * Math.sin(6.0 * x * pi) + 20.0 * Math.sin(2.0 * x * pi)) * 2.0) /
      3.0;
    ret +=
      ((20.0 * Math.sin(x * pi) + 40.0 * Math.sin((x / 3.0) * pi)) * 2.0) / 3.0;
    ret +=
      ((150.0 * Math.sin((x / 12.0) * pi) + 300.0 * Math.sin((x / 30.0) * pi)) *
        2.0) /
      3.0;
    return ret;
  }

  private transform(lat: number, lon: number) {
    if (this.outOfChina(lat, lon)) {
      return [lat, lon];
    }
    var dLat = this.transformLat(lon - 105.0, lat - 35.0);
    var dLon = this.transformLon(lon - 105.0, lat - 35.0);
    var radLat = (lat / 180.0) * pi;
    var magic = Math.sin(radLat);
    magic = 1 - ee * magic * magic;
    var sqrtMagic = Math.sqrt(magic);
    dLat = (dLat * 180.0) / (((a * (1 - ee)) / (magic * sqrtMagic)) * pi);
    dLon = (dLon * 180.0) / ((a / sqrtMagic) * Math.cos(radLat) * pi);
    var mgLat = lat + dLat;
    var mgLon = lon + dLon;
    return [mgLat, mgLon];
  }

  /**
    判断经纬度是否超出中国境内
    @param {number} latitude 纬度
    @param {number} longitude 经度
  */
  outOfChina(lat: number | undefined, lon: number | undefined) {
    if (lon == undefined || lat == undefined) return true;
    if (lon < 72.004 || lon > 137.8347) return true;
    if (lat < 0.8293 || lat > 55.8271) return true;
    return false;
  }

  /**
   * 84 to 火星坐标系 (GCJ-02) World Geodetic System ==> Mars Geodetic System
   *
   * @param lat
   * @param lon
   * @return
   */
  gps84_To_Gcj02(lat: number, lon: number) {
    if (this.outOfChina(lat, lon)) {
      return [lat, lon];
    }
    var dLat = this.transformLat(lon - 105.0, lat - 35.0);
    var dLon = this.transformLon(lon - 105.0, lat - 35.0);
    var radLat = (lat / 180.0) * pi;
    var magic = Math.sin(radLat);
    magic = 1 - ee * magic * magic;
    var sqrtMagic = Math.sqrt(magic);
    dLat = (dLat * 180.0) / (((a * (1 - ee)) / (magic * sqrtMagic)) * pi);
    dLon = (dLon * 180.0) / ((a / sqrtMagic) * Math.cos(radLat) * pi);
    var mgLat = lat + dLat;
    var mgLon = lon + dLon;
    return [mgLat, mgLon];
  }

  /**
   * * 火星坐标系 (GCJ-02) to 84 * * @param lon * @param lat * @return
   * */
  gcj02_To_Gps84(lat: number, lon: number) {
    var gps = this.transform(lat, lon);
    var lontitude = lon * 2 - gps[1];
    var latitude = lat * 2 - gps[0];
    return [latitude, lontitude];
  }
  /**
   * 火星坐标系 (GCJ-02) 与百度坐标系 (BD-09) 的转换算法 将 GCJ-02 坐标转换成 BD-09 坐标
   *
   * @param lat
   * @param lon
   */
  gcj02_To_Bd09(lat: number, lon: number) {
    var x = lon,
      y = lat;
    var z = Math.sqrt(x * x + y * y) + 0.00002 * Math.sin(y * x_pi);
    var theta = Math.atan2(y, x) + 0.000003 * Math.cos(x * x_pi);
    var tempLon = z * Math.cos(theta) + 0.0065;
    var tempLat = z * Math.sin(theta) + 0.006;
    return [tempLat, tempLon];
  }

  /**
   * * 火星坐标系 (GCJ-02) 与百度坐标系 (BD-09) 的转换算法 * * 将 BD-09 坐标转换成GCJ-02 坐标 * * @param
   * bd_lat * @param bd_lon * @return
   */
  bd09_To_Gcj02(lat: number, lon: number) {
    var x = lon - 0.0065,
      y = lat - 0.006;
    var z = Math.sqrt(x * x + y * y) - 0.00002 * Math.sin(y * x_pi);
    var theta = Math.atan2(y, x) - 0.000003 * Math.cos(x * x_pi);
    var tempLon = z * Math.cos(theta);
    var tempLat = z * Math.sin(theta);
    return [tempLat, tempLon];
  }

  /**将gps84转为bd09
   * @param lat
   * @param lon
   * @return
   */
  gps84_To_bd09(lat: number, lon: number) {
    var gcj02 = this.gps84_To_Gcj02(lat, lon);
    var bd09 = this.gcj02_To_Bd09(gcj02[0], gcj02[1]);
    return bd09;
  }

  bd09_To_gps84(lat: number, lon: number) {
    var gcj02 = this.bd09_To_Gcj02(lat, lon);
    var gps84 = this.gcj02_To_Gps84(gcj02[0], gcj02[1]);
    //保留小数点后六位
    gps84[0] = this.retain6(gps84[0]);
    gps84[1] = this.retain6(gps84[1]);
    return gps84;
  }

  /**保留小数点后六位
   * @param num
   * @return
   */
  retain6(num: number) {
    //String result = String.format("%.6f", num);

    return Number(num.toFixed(6));
  }
}

export default new GPSUtil();

//进行经纬度转换为距离的计算
function Rad(d: number) {
  return (d * Math.PI) / 180.0; //经纬度转换成三角函数中度分表形式。
}

type Point = { lat: number; lng: number };

/**计算距离，参数分别为第一点的纬度，经度；第二点的纬度，经度 */
function getDistance(
  { lat: lat1, lng: lng1 }: Point,
  { lat: lat2, lng: lng2 }: Point,
) {
  var radLat1 = Rad(lat1);
  var radLat2 = Rad(lat2);
  var a = radLat1 - radLat2;
  var b = Rad(lng1) - Rad(lng2);
  var s =
    2 *
    Math.asin(
      Math.sqrt(
        Math.pow(Math.sin(a / 2), 2) +
          Math.cos(radLat1) * Math.cos(radLat2) * Math.pow(Math.sin(b / 2), 2),
      ),
    );
  s = s * 6378.137; // EARTH_RADIUS;
  s = Math.round(s * 10000) / 10000; //输出为公里
  //s=s.toFixed(4);
  return s;
}

function calculateZoomLevel(distance: number) {
  var zoom = [
    '50',
    '100',
    '200',
    '500',
    '1000',
    '2000',
    '5000',
    '10000',
    '20000',
    '25000',
    '50000',
    '100000',
    '200000',
    '500000',
    '1000000',
    '2000000',
  ];
  for (var i = 0, zoomLen = zoom.length; i < zoomLen; i++) {
    if (Number(zoom[i]) - distance > 0) {
      return 18 - i + 3;
    }
  }
  return 3;
}

function getEdgePoints(list: Point[]): Point[] {
  var lLng: number | undefined,
    rLng: number | undefined,
    tLat: number | undefined,
    bLat: number | undefined;
  list.forEach((e) => {
    if (!lLng || Number(e.lng) < lLng) lLng = Number(e.lng);
    if (!rLng || Number(e.lng) > rLng) rLng = Number(e.lng);
    if (!tLat || Number(e.lat) > tLat) tLat = Number(e.lat);
    if (!bLat || Number(e.lat) < bLat) bLat = Number(e.lat);
  });

  return [
    { lng: lLng!, lat: tLat! },
    { lng: rLng!, lat: bLat! },
    { lng: lLng! + (rLng! - lLng!) / 2, lat: bLat! + (tLat! - bLat!) / 2 },
  ];
}

export function getCenterPointAndZoomLevel(list: Point[]) {
  if (!list || list.length === 0) {
    return {
      center: { lng: 120.21551, lat: 30.25308 },
      zoom: 8,
    };
  }

  const [p1, p2, p] = getEdgePoints(list);
  const distance = getDistance(p1, p2);
  const zoom = calculateZoomLevel(distance * 1000);
  return {
    center: p,
    zoom,
  };
}
