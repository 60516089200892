// This file is generated by the VG SwaggerGenerator.
// Do not edit, next time generation will overwrite it!
import * as models from '../../entitys';
import { http } from '../base_http';


/** 
 * @description: 设备调度
 * @operationID: deviceDispatch
 * 
 * @return {models.DeviceDispatchResp}
 */
export async function getV1SystemSmallScreenDeviceDispatch(): Promise<models.DeviceDispatchResp> {
  const res = await http.get('/v1/system/smallScreen/deviceDispatch');
  return models.DeviceDispatchResp.fromJson(res.data);
}

/** 
 * @description: 设备维保
 * @operationID: equipmentMaintenance
 * 
 * @return {models.EquipmentMaintenanceResp}
 */
export async function getV1SystemSmallScreenEquipmentMaintenance(): Promise<models.EquipmentMaintenanceResp> {
  const res = await http.get('/v1/system/smallScreen/equipmentMaintenance');
  return models.EquipmentMaintenanceResp.fromJson(res.data);
}

/** 
 * @description: 项目总览
 * @operationID: projectOverview
 * 
 * @return {models.ProjectOverviewResp}
 */
export async function getV1SystemSmallScreenProjectOverview(): Promise<models.ProjectOverviewResp> {
  const res = await http.get('/v1/system/smallScreen/projectOverview');
  return models.ProjectOverviewResp.fromJson(res.data);
}

/** 
 * @description: 租赁订单概览
 * @operationID: rentalOrder
 * 
 * @return {models.RentalOrderResp}
 */
export async function getV1SystemSmallScreenRentalOrder(): Promise<models.RentalOrderResp> {
  const res = await http.get('/v1/system/smallScreen/rentalOrder');
  return models.RentalOrderResp.fromJson(res.data);
}
