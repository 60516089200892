// This file is generated by the VG SwaggerGenerator.
// Do not edit, next time generation will overwrite it!
import * as models from '../../entitys';
import { http } from '../base_http';


/** 
 * @description: 报警列表
 * @operationID: alarmList
 * 
 * @queryParam {string?} province:  省份
 * @return {models.AlarmListResp[]}
 */
export async function getV1CenterCommonViewAlarmList(query: {
  province?: string,
}): Promise<models.AlarmListResp[]> {
  const res = await http.get('/v1/center/commonView/alarmList', query);
  return res.data ? (res.data as any[]).map<models.AlarmListResp>((v: any) => models.AlarmListResp.fromJson(v)) : [];
}

/** 
 * @description: 设备列表
 * @operationID: deviceList
 * 
 * @queryParam {string?} province:  省份
 * @return {models.DeviceListResp[]}
 */
export async function getV1CenterCommonViewDeviceList(query: {
  province?: string,
}): Promise<models.DeviceListResp[]> {
  const res = await http.get('/v1/center/commonView/deviceList', query);
  return res.data ? (res.data as any[]).map<models.DeviceListResp>((v: any) => models.DeviceListResp.fromJson(v)) : [];
}

/** 
 * @description: 项目进展
 * @operationID: projectList
 * 
 * @queryParam {string?} province:  省份
 * @return {models.ProjectListResp[]}
 */
export async function getV1CenterCommonViewProjectList(query: {
  province?: string,
}): Promise<models.ProjectListResp[]> {
  const res = await http.get('/v1/center/commonView/projectList', query);
  return res.data ? (res.data as any[]).map<models.ProjectListResp>((v: any) => models.ProjectListResp.fromJson(v)) : [];
}

/** 
 * @description: 调度路线
 * @operationID: scheduleList
 * 
 * @queryParam {string?} province:  省份
 * @return {models.ScheduleListResp[]}
 */
export async function getV1CenterCommonViewScheduleList(query: {
  province?: string,
}): Promise<models.ScheduleListResp[]> {
  const res = await http.get('/v1/center/commonView/scheduleList', query);
  return res.data ? (res.data as any[]).map<models.ScheduleListResp>((v: any) => models.ScheduleListResp.fromJson(v)) : [];
}

/** 
 * @description: 当前城市位置查询
 * @operationID: location
 * 
 * @queryParam {string?} ip:  ip地址
 * @return {models.LocationResp}
 */
export async function getV1CenterCommonViewLocation(query: {
  ip?: string,
}): Promise<models.LocationResp> {
  const res = await http.get('/v1/center/commonView/location', query);
  return models.LocationResp.fromJson(res.data);
}
