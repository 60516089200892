/*
 * @Author: zdd
 * @Date: 2023-12-18 11:32:17
 * @LastEditors: zdd dongdong@grizzlychina.com
 * @LastEditTime: 2023-12-18 11:33:44
 * @FilePath: sort.ts
 */

/**
 * Sorts an array of data based on the specified sort keys and sort key function.
 *
 * @param {any[]} data - The array of data to be sorted.
 * @param {string[]} config.sortKeys - The array of sort keys in the desired order.
 * @param {function} config.sortKey - The function used to extract the sort key from each item in the data array.
 * @param {function} [config.subCompareFn] - An optional function used to compare two items within the same sort key group.
 * @return {any[]} The sorted array of data.
 */
export const customSort = (
  data: any[],
  config: {
    sortKeys: string[];
    sortKey: (item: any) => string;
    subCompareFn?: (a: any, b: any) => number;
  },
) => {
  const { sortKeys, sortKey, subCompareFn } = config;
  const tempData = new Map<string, any[]>(sortKeys.map((key) => [key, []]));
  data.forEach((item) => {
    if (item) {
      tempData.get(sortKey(item))?.push(item);
    }
  });
  if (subCompareFn) {
    sortKeys.forEach((key) => {
      tempData.get(key)?.sort(subCompareFn);
    });
  }

  let list: any[] = [];
  sortKeys.forEach((key) => {
    list = list.concat(tempData.get(key));
  });
  return list;
};
