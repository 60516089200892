// This file is generated by the VG SwaggerGenerator.
// Do not edit, next time generation will overwrite it!
import * as models from '../../entitys';
import { http } from '../base_http';


/** 
 * @description: 设备基本信息
 * @operationID: deviceDetail
 * 
 * @queryParam {number} device_id:  设备id
 * @return {models.DeviceDetailResp}
 */
export async function getV1CenterDeviceViewDeviceDetail(query: {
  device_id: number,
}): Promise<models.DeviceDetailResp> {
  const res = await http.get('/v1/center/deviceView/deviceDetail', query);
  return models.DeviceDetailResp.fromJson(res.data);
}

/** 
 * @description: 设备记录
 * @operationID: deviceRecord
 * 
 * @queryParam {number} device_id:  设备id
 * @return {models.DeviceRecordResp}
 */
export async function getV1CenterDeviceViewDeviceRecord(query: {
  device_id: number,
}): Promise<models.DeviceRecordResp> {
  const res = await http.get('/v1/center/deviceView/deviceRecord', query);
  return models.DeviceRecordResp.fromJson(res.data);
}
