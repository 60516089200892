import {
  getV1CenterAreaViewAreaOverview,
  getV1CenterAreaViewDeviceAttendance,
  getV1CenterAreaViewServiceQuality,
  getV1CenterCommonViewProjectList,
} from '@/api/requests';
// import { NationalOverviewResp } from '@/api2/entitys';

export interface IAreaOverview {
  device_count: number;
  driver_count: number;
  device_distribution: {
    device_type: string;
    device_count: number;
  }[];
}

export interface IDeviceAttendance {
  last_month_device_attendance: string;
  today_device_attendance: string;
  last_month_device_type_attendance: {
    device_type: string;
    attendance: string;
  }[];
  today_device_type_attendance: {
    device_type: string;
    attendance: string;
  }[];
}

export interface IServiceQuality {
  composite_score: number;
  complaints_count: number;
  complaints_distribution: {
    complaints_type: string;
    complaints_count: number;
  }[];
}

export default {
  name: 'areaView',
  state: {
    areaOverview: {} as IAreaOverview,
    deviceAttendance: {} as IDeviceAttendance,
    serviceQuality: {} as IServiceQuality,
    projectList: [],
  },
  reducers: {
    setAreaOverview(state: any, { payload }: any) {
      return {
        ...state,
        areaOverview: payload as IAreaOverview,
      };
    },
    setDeviceAttendance(state: any, { payload }: any) {
      return {
        ...state,
        deviceAttendance: payload,
      };
    },
    setServiceQuality(state: any, { payload }: any) {
      return {
        ...state,
        serviceQuality: payload,
      };
    },
    setProjectList(state: any, { payload }: any) {
      return {
        ...state,
        projectList: payload,
      };
    },
  },
  effects: {
    *getAreaOverview({ payload }: any, { call, put }: any): any {
      const data = yield call(getV1CenterAreaViewAreaOverview, payload);
      if (data) {
        yield put({
          type: 'setAreaOverview',
          payload: data,
        });
      } else {
        console.log('getAreaOverview error', data);
        return false;
      }
    },

    *getDeviceAttendance({ payload }: any, { call, put }: any): any {
      const data = yield call(getV1CenterAreaViewDeviceAttendance, payload);
      if (data) {
        yield put({
          type: 'setDeviceAttendance',
          payload: data,
        });
      } else {
        console.log('getDeviceAttendance error', data);
        return false;
      }
    },

    *getServiceQuality({ payload }: any, { call, put }: any): any {
      const data = yield call(getV1CenterAreaViewServiceQuality, payload);
      if (data) {
        yield put({
          type: 'setServiceQuality',
          payload: data,
        });
      } else {
        console.log('getServiceQuality error', data);
        return false;
      }
    },

    *getProjectList({ payload }: any, { call, put }: any): any {
      const data = yield call(getV1CenterCommonViewProjectList);
      console.log('data', data);
      if (data) {
        yield put({
          type: 'setProjectList',
          payload: data,
        });
      } else {
        console.log('getProjectList error', data);
        return false;
      }
    },

    *getPageData({ payload }: any, { call, put }: any): any {
      yield put({
        type: 'getProjectList',
        payload,
      });

      yield put({
        type: 'getAreaOverview',
        payload,
      });

      yield put({
        type: 'getServiceQuality',
        payload,
      });
    },
  },
};
