/*
 * @Author: zdd
 * @Date: 2024-01-02 10:50:12
 * @LastEditors: zdd dongdong@grizzlychina.com
 * @LastEditTime: 2024-01-16 16:02:47
 * @FilePath: index.tsx
 */
import { ReactComponent as DangerSvg } from '@/assets/images/danger.svg';
import { ReactComponent as ProjectSvg } from '@/assets/images/project.svg';
import { SocketSingle } from '@/models/global_socket';
import { useMap, useWebSocket } from 'ahooks';
import { notification } from 'antd';
import { createContext, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';

const CLIENT_ID = 'big_client_id';

const uuid = localStorage.getItem(CLIENT_ID) || uuidv4();

const initialValue: Map<string, any> = new Map();
export const SocketContext = createContext(initialValue);

const GlobalSocketProvider = (props: any) => {
  const [api, contextHolder] = notification.useNotification();
  const [contextMap] = useMap(initialValue);

  const { readyState, sendMessage } = useWebSocket(
    `${WS_API}/ws?client_id=${uuid}&client_type=screen`,
    {
      manual: false,
      // 重试次数
      reconnectLimit: 10000,
      // 重试时间间隔（ms）
      reconnectInterval: 5000,
      onOpen: () => console.log('%cws onOpen: 连接成功', 'color: green;'),
      onClose: () => console.log('%cws onClose: 连接关闭', 'color: red;'),
      onError: () => console.log('%cws onError: 连接错误', 'color: red;'),
      onMessage: (e) => {
        const result = SocketSingle.onSocketMessage(e);

        if (result?.message) {
          if (result.type === 'event-info') {
            api['info']({
              message: result.message,
              icon: <ProjectSvg />,
              closeIcon: false,
              duration: 10,
            });
          } else {
            api['error']({
              message: result.message,
              icon: (
                <DangerSvg
                  style={{
                    transform: 'scale(0.8)',
                    marginTop: '-10px',
                  }}
                />
              ),
              closeIcon: false,
              duration: 10,
            });
          }
        }
      },
    },
  );

  SocketSingle.config({ readyState, sendMessage });

  useEffect(() => {
    switch (readyState) {
      case 0:
        console.log('%cws connecting: 连接中...', 'color: blue;');
        break;
      case 1:
        if (!localStorage.getItem(CLIENT_ID)) {
          localStorage.setItem(CLIENT_ID, uuid);
        }
        break;
      case 3:
        console.log('%cws onClose: 连接中断', 'color: red;');
        break;
    }
  }, [readyState]);

  return (
    <>
      {contextHolder}
      <SocketContext.Provider value={contextMap}>
        {props.children}
      </SocketContext.Provider>
    </>
  );
};

export default GlobalSocketProvider;
