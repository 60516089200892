type IRequest = (url: string, opts?: any) => Promise<any>;

function download(url: string, params?: Record<string, any>) {
  return EnterpriseReq.request(url, {
    responseType: 'blob',
    params,
  });
}

export class EnterpriseReq {
  private static _request: IRequest | undefined;
  static installHttp(request: any) {
    EnterpriseReq._request = request;
  }

  static get request() {
    if (!EnterpriseReq._request) {
      throw new Error('请先初始化 http： EnterpriseReq.installHttp');
    }
    return EnterpriseReq._request;
  }
}

// 'https://testapi.grizzlychina.com/business'
export const http = {
  get: (url: string, params?: any) => EnterpriseReq.request(url, { params }),
  post: (url: string, data?: Record<string, any>) =>
    EnterpriseReq.request(url, { data, method: 'POST' }),
  put: (url: string, data?: Record<string, any>) =>
    EnterpriseReq.request(url, { data, method: 'PUT' }),
  delete: (url: string, data?: Record<string, any>) =>
    EnterpriseReq.request(url, { method: 'DELETE', data }),
  download,
  upload: (file: File) => {
    const formData = new FormData();
    formData.append('file', file);
    return EnterpriseReq.request('/upload/file', {
      method: 'POST',
      data: formData,
    });
  },
};

export interface PageResp<T> {
  data: T[];
  page: number;
  size: number;
  total: number;
}
