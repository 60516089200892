// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
// defineApp
export { defineApp } from './core/defineApp'
export type { RuntimeConfig } from './core/defineApp'
// plugins
export { connect, useDispatch, useStore, useSelector, getDvaApp } from '/mnt/builds/kJruUgmc/0/frontend/xt-web-monorepo/projects/xt-visual/src/.umi-production/plugin-dva';
export { useRequest, UseRequestProvider, request, getRequestInstance } from '/mnt/builds/kJruUgmc/0/frontend/xt-web-monorepo/projects/xt-visual/src/.umi-production/plugin-request';
export { StyleSheetManager, ThemeProvider, createGlobalStyle, css, keyframes, styled, useTheme } from '/mnt/builds/kJruUgmc/0/frontend/xt-web-monorepo/projects/xt-visual/src/.umi-production/plugin-styledComponents';
export { proxy, useSnapshot, snapshot, subscribe, subscribeKey, proxyWithComputed, proxyWithHistory, proxyWithDevtools, proxyMap, proxySet, derive, underive, useProxy, ref, watch } from '/mnt/builds/kJruUgmc/0/frontend/xt-web-monorepo/projects/xt-visual/src/.umi-production/plugin-valtio';
// plugins types.d.ts
export * from '/mnt/builds/kJruUgmc/0/frontend/xt-web-monorepo/projects/xt-visual/src/.umi-production/plugin-antd/types.d';
export * from '/mnt/builds/kJruUgmc/0/frontend/xt-web-monorepo/projects/xt-visual/src/.umi-production/plugin-dva/types.d';
export * from '/mnt/builds/kJruUgmc/0/frontend/xt-web-monorepo/projects/xt-visual/src/.umi-production/plugin-request/types.d';
// @umijs/renderer-*
export { createBrowserHistory, createHashHistory, createMemoryHistory, Helmet, HelmetProvider, createSearchParams, generatePath, matchPath, matchRoutes, Navigate, NavLink, Outlet, resolvePath, useLocation, useMatch, useNavigate, useOutlet, useOutletContext, useParams, useResolvedPath, useRoutes, useSearchParams, useAppData, useClientLoaderData, useRouteProps, useSelectedRoutes, useServerLoaderData, renderClient, __getRoot, Link, useRouteData, __useFetcher, withRouter } from '/mnt/builds/kJruUgmc/0/frontend/xt-web-monorepo/node_modules/.pnpm/@umijs+renderer-react@4.0.89_react-dom@18.1.0_react@18.1.0/node_modules/@umijs/renderer-react';
export type { History } from '/mnt/builds/kJruUgmc/0/frontend/xt-web-monorepo/node_modules/.pnpm/@umijs+renderer-react@4.0.89_react-dom@18.1.0_react@18.1.0/node_modules/@umijs/renderer-react'
// umi/client/client/plugin
export { ApplyPluginsType, PluginManager } from '/mnt/builds/kJruUgmc/0/frontend/xt-web-monorepo/node_modules/.pnpm/umi@4.0.89_@babel+core@7.23.5_@types+node@17.0.45_@types+react@18.2.42_eslint@8.55.0_prettier_rm4h3lyqvklgpdlzw6lnpl3cxu/node_modules/umi/client/client/plugin.js';
export { history, createHistory } from './core/history';
export { terminal } from './core/terminal';
// react ssr
export const useServerInsertedHTML: Function = () => {};
