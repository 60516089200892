// This file is generated by the VG SwaggerGenerator.
// Do not edit, next time generation will overwrite it!
import * as models from '../../entitys';
import { http } from '../base_http';


/** 
 * @description: 场内设备
 * @operationID: device
 * 
 * @queryParam {number} project_id:  项目id
 * @return {models.DeviceResp[]}
 */
export async function getV1CenterProjectViewDevice(query: {
  project_id: number,
}): Promise<models.DeviceResp[]> {
  const res = await http.get('/v1/center/projectView/device', query);
  return res.data ? (res.data as any[]).map<models.DeviceResp>((v: any) => models.DeviceResp.fromJson(v)) : [];
}

/** 
 * @description: 项目基本信息
 * @operationID: projectDetail
 * 
 * @queryParam {number} project_id:  项目id
 * @return {models.ProjectDetailResp}
 */
export async function getV1CenterProjectViewProjectDetail(query: {
  project_id: number,
}): Promise<models.ProjectDetailResp> {
  const res = await http.get('/v1/center/projectView/projectDetail', query);
  return models.ProjectDetailResp.fromJson(res.data);
}

/** 
 * @description: 项目时间线事件
 * @operationID: projectEvent
 * 
 * @queryParam {number} project_id:  项目id
 * @return {models.ProjectEventResp[]}
 */
export async function getV1CenterProjectViewProjectEvent(query: {
  project_id: number,
}): Promise<models.ProjectEventResp[]> {
  const res = await http.get('/v1/center/projectView/projectEvent', query);
  return res.data ? (res.data as any[]).map<models.ProjectEventResp>((v: any) => models.ProjectEventResp.fromJson(v)) : [];
}

/** 
 * @description: 工时/油耗
 * @operationID: workCurve
 * 
 * @queryParam {number} project_id:  项目id
 * @return {models.WorkCurveResp}
 */
export async function getV1CenterProjectViewWorkCurve(query: {
  project_id: number,
}): Promise<models.WorkCurveResp> {
  const res = await http.get('/v1/center/projectView/workCurve', query);
  return models.WorkCurveResp.fromJson(res.data);
}

/** 
 * @description: 设备行驶轨迹
 * @operationID: deviceTrajectory
 * 
 * @queryParam {number} project_id:  项目id
 * @return {models.DeviceTrajectoryResp}
 */
export async function getV1CenterProjectViewDeviceTrajectory(query: {
  project_id: number,
}): Promise<models.DeviceTrajectoryResp> {
  const res = await http.get('/v1/center/projectView/deviceTrajectory', query);
  return models.DeviceTrajectoryResp.fromJson(res.data);
}
