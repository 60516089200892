// This file is generated by the VG SwaggerGenerator.
// Do not edit, next time generation will overwrite it!
import * as models from '../../entitys';
import { http } from '../base_http';


/** 
 * @description: 区域概览
 * @operationID: areaOverview
 * 
 * @queryParam {string} province:  省份
 * @return {models.AreaOverviewResp}
 */
export async function getV1CenterAreaViewAreaOverview(query: {
  province: string,
}): Promise<models.AreaOverviewResp> {
  const res = await http.get('/v1/center/areaView/areaOverview', query);
  return models.AreaOverviewResp.fromJson(res.data);
}

/** 
 * @description: 设备出勤
 * @operationID: deviceAttendance
 * 
 * @queryParam {string} province:  省份
 * @return {models.DeviceAttendanceResp}
 */
export async function getV1CenterAreaViewDeviceAttendance(query: {
  province: string,
}): Promise<models.DeviceAttendanceResp> {
  const res = await http.get('/v1/center/areaView/deviceAttendance', query);
  return models.DeviceAttendanceResp.fromJson(res.data);
}

/** 
 * @description: 服务质量
 * @operationID: serviceQuality
 * 
 * @queryParam {string} province:  省份
 * @return {models.ServiceQualityResp}
 */
export async function getV1CenterAreaViewServiceQuality(query: {
  province: string,
}): Promise<models.ServiceQualityResp> {
  const res = await http.get('/v1/center/areaView/serviceQuality', query);
  return models.ServiceQualityResp.fromJson(res.data);
}
