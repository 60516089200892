// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import React from 'react';

export async function getRoutes() {
  const routes = {"1":{"name":"数据大屏","path":"/big","id":"1"},"2":{"name":"首页","path":"/","id":"2"},"3":{"name":"设备调度","path":"/dispatch","id":"3"},"4":{"name":"设备维保","path":"/repair","id":"4"},"5":{"name":"项目总览","path":"/project","id":"5"},"6":{"name":"租赁订单概览","path":"/order","id":"6"},"7":{"name":"设备排期表","path":"/scheduleTable","id":"7"},"8":{"name":"项目进度表","path":"/projectTable","id":"8"},"9":{"name":"导航","path":"/menu","id":"9"},"10":{"name":"主屏幕控制端","path":"/terminal","id":"10"}} as const;
  return {
    routes,
    routeComponents: {
'1': React.lazy(() => import(/* webpackChunkName: "p__Big__index" */'@/pages/Big/index.tsx')),
'2': React.lazy(() => import(/* webpackChunkName: "p__Startup__index" */'@/pages/Startup/index.tsx')),
'3': React.lazy(() => import(/* webpackChunkName: "p__Dispatch__index" */'@/pages/Dispatch/index.tsx')),
'4': React.lazy(() => import(/* webpackChunkName: "p__Repair__index" */'@/pages/Repair/index.tsx')),
'5': React.lazy(() => import(/* webpackChunkName: "p__ProjectOverView__index" */'@/pages/ProjectOverView/index.tsx')),
'6': React.lazy(() => import(/* webpackChunkName: "p__OrderView__index" */'@/pages/OrderView/index.tsx')),
'7': React.lazy(() => import(/* webpackChunkName: "p__OrderView__schedule-table" */'@/pages/OrderView/schedule-table.tsx')),
'8': React.lazy(() => import(/* webpackChunkName: "p__OrderView__project-table" */'@/pages/OrderView/project-table.tsx')),
'9': React.lazy(() => import(/* webpackChunkName: "p__Menu__index" */'@/pages/Menu/index.tsx')),
'10': React.lazy(() => import(/* webpackChunkName: "p__terminal__index" */'@/pages/terminal/index.tsx')),
},
  };
}
