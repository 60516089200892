import { request } from 'umi';

interface Http {
  get: (url: string, params?: any) => Promise<any>;
  post: (url: string, data?: Record<string, any>) => Promise<any>;
  put: (url: string, data?: Record<string, any>) => Promise<any>;
  delete: (url: string, data?: Record<string, any>) => Promise<any>;
  download: (
    url: string,
    params?: Record<string, any> | undefined,
  ) => Promise<any>;
  upload: (file: File) => Promise<any>;
}

function download(url: string, params?: Record<string, any>) {
  return request(url, {
    responseType: 'blob',
    params,
  });
}

export const http: Http = {
  get: (url: string, params?: any) => request(url, { params }),
  post: (url: string, data?: Record<string, any>) =>
    request(url, { data, method: 'POST' }),
  put: (url: string, data?: Record<string, any>) =>
    request(url, { data, method: 'PUT' }),
  delete: (url: string, data?: Record<string, any>) =>
    request(url, { method: 'DELETE', data }),
  download,
  upload: (file: File) => {
    const formData = new FormData();
    formData.append('file', file);
    return request('/upload/file', { method: 'POST', data: formData });
  },
};
