export default {
  name: 'global',
  state: {
    socketData: [],
  },

  effects: {},

  reducers: {
    setSocketData: (state: any, { payload }: any) => {
      return {
        ...state,
        socketData: payload,
      };
    },
  },
};
