import {
  getV1CenterDeviceViewDeviceDetail,
  getV1CenterDeviceViewDeviceRecord,
} from '@/api/requests';
// import { deviceDetail, deviceRecord } from '@/service/api/deviceView';
import { DeviceDetailResp, DeviceRecordResp } from '@/api/entitys';

export default {
  name: 'deviceView',
  state: {
    loading: true,
    deviceDetail: {} as DeviceDetailResp,
    deviceRecord: {} as DeviceRecordResp,
  },
  effects: {
    *getDevicePageData({ payload }: any, { call, put }: any) {
      const deviceDetailData: DeviceDetailResp = yield call(
        getV1CenterDeviceViewDeviceDetail,
        payload,
      );
      const deviceRecordData: DeviceRecordResp = yield call(
        getV1CenterDeviceViewDeviceRecord,
        payload,
      );
      console.log('deviceRecordData', deviceRecordData);
      yield put({
        type: 'setDevicePageData',
        payload: {
          deviceDetail: deviceDetailData,
          deviceRecord: deviceRecordData,
          loading: false,
        },
      });
    },
  },
  reducers: {
    setDevicePageData(state: any, { payload }: any) {
      return {
        ...state,
        ...payload,
      };
    },
  },
};
