// This file is generated by the VG SwaggerGenerator.
// Do not edit, next time generation will overwrite it!
import * as models from '../../entitys';
import { http } from '../base_http';


/** 
 * @description: 全国概述
 * @operationID: nationalOverview
 * 
 * @return {models.NationalOverviewResp}
 */
export async function getV1CenterGlobalViewNationalOverview(): Promise<models.NationalOverviewResp> {
  const res = await http.get('/v1/center/globalView/nationalOverview');
  return models.NationalOverviewResp.fromJson(res.data);
}

/** 
 * @description: 省份概述
 * @operationID: provinceOverview
 * 
 * @return {models.ProvinceOverviewResp}
 */
export async function getV1CenterGlobalViewProvinceOverview(): Promise<models.ProvinceOverviewResp> {
  const res = await http.get('/v1/center/globalView/provinceOverview');
  return models.ProvinceOverviewResp.fromJson(res.data);
}
