/*
 * @Author: zdd
 * @Date: 2023-11-21 18:36:09
 * @LastEditors: zdd dongdong@grizzlychina.com
 * @LastEditTime: 2024-01-16 16:02:32
 * @FilePath: app.ts
 */
import { message } from 'antd';
import { startMonitor } from 'common';
import { DeviceV2Req } from 'domain-device/api-v2/base_http';
import { EnterpriseReq } from 'domain-enterprise-v2/api/base_http';
import { ProjectV2Req } from 'domain-project/api-v2/base_http';
import { ScreenV2Req } from 'domain-screen/api-v2/base_http';
import React from 'react';
import {
  AxiosError,
  history,
  request as umiRequest,
  RequestConfig,
  RequestOptions,
} from 'umi';
import GlobalSocketProvider from './components/GlobalSocketProvider';

startMonitor('tZDfAYdCDH2', { usePerformance: false });

export const antd = (memo: any) => {
  memo.appConfig = {
    message: {
      maxCount: 3,
    },
  };

  return memo;
};

// export const layout: RunTimeLayoutConfig = () => {
//   return {
//     title: '',
//     menuHeaderRender: false,
//     headerRender: false,
//     footerRender: false,
//     menuRender: false,
//   };
// };

const authMiddleware = (config: any) => {
  if (
    config.url.startsWith('/v1') &&
    !config.url.startsWith('//:') &&
    !config.url.startsWith('/project')
  ) {
    // config.url = APP_API + config.url;
  }

  return config;
};

export const request: RequestConfig = {
  requestInterceptors: [
    (config: RequestOptions) => {
      config.url = APP_API + config.url;
      const token = localStorage.getItem('token');
      config.headers = { 'X-Auth-Token': token || 'app:1:1:code' };
      return config;
    },
  ],

  responseInterceptors: [
    (response) => {
      if (response.status >= 200 && response.status < 300) {
        if ((response.data as any)?.status > 400) {
          message.error((response.data as any)?.error_message);
          // throw new Error((response.data as any)?.error_message);
        } else {
          return response;
        }
      }
      const error = new Error(response.statusText);
      throw error;
    },
  ],
  errorConfig: {
    errorHandler: (error: AxiosError | Error, opts: RequestOptions) => {
      if ((error as AxiosError).code === 'ERR_NETWORK') {
        message.error('网络错误，请检查网络连接');
        return;
      } else if (
        (error as AxiosError).code === 'ERR_BAD_REQUEST' &&
        error.message.includes('401')
      ) {
        message.error('登录信息已过期，请重新登录');
        history.push('/login');
        return;
      }
    },
  },
};

export function rootContainer(container: any) {
  return React.createElement(GlobalSocketProvider, null, container);
}

EnterpriseReq.installHttp(umiRequest);
DeviceV2Req.installHttp(umiRequest);
ProjectV2Req.installHttp(umiRequest);
ScreenV2Req.installHttp(umiRequest);
