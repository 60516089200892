import VgMonitor from '@vg-monitor/core';
import performance from '@vg-monitor/performance';
import recordscreen from '@vg-monitor/recordscreen';
import { generateUUID } from '@vg-monitor/shared';

type MonitorOption = {
  getUserId?: () => string;
  usePerformance?: false;
  useRecordscreen?: false;
};
function startMonitor(apikey: string, option?: MonitorOption) {
  const baseUrl = 'https://vg.grizzlychina.com/report';

  VgMonitor.init({
    dsn: `${baseUrl}/reportData`,
    apikey,
    silentWhiteScreen: true,
    skeletonProject: true,
    repeatCodeError: true,
    getUserId:
      option?.getUserId ??
      function () {
        let userId = localStorage.getItem('user_id');
        if (!userId) {
          userId = generateUUID();
          localStorage.setItem('user_id', userId);
        }
        return userId;
      },
    handleHttpStatus(data) {
      let { url, response } = data;
      if (url.includes('/getErrorList')) {
        // code为200，接口正常，反之亦然
        let { code } =
          typeof response === 'string' ? JSON.parse(response) : response;
        return code === 200 ? true : false;
      } else {
        return true;
      }
    },
  });

  if (option?.usePerformance !== false) {
    VgMonitor.use(performance, {});
  }

  if (option?.useRecordscreen !== false) {
    VgMonitor.use(recordscreen, {});
  }
}

export { startMonitor };
