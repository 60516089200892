/*
 * @Author: zdd
 * @Date: 2023-12-27 10:46:59
 * @LastEditors: zdd dongdong@grizzlychina.com
 * @LastEditTime: 2023-12-28 10:57:46
 * @FilePath: event.ts
 */
export const eventChange = (eventType: string, ...args: any[]) => {
  const eventMap = {
    // 创建
    new_order: '收到新的租赁订单, 请及时对接',
    // 取消订单
    cancel_order: `${args[0]!}已取消订单`,
    // 确认订单
    confirm_order: ` ${args[0] ? args[0] : ''}已确认订单, 项目已启动`,
    // 设备调度
    device_dispatches: `${args[0]!}已调度至${args[1]!}`,
    // 项目完工
    project_finish: `${args[0]!}已完工`,
    // 项目中止
    project_stop: `${args[0]!}已中止项目`,
    // 项目重启
    project_restart: `${args[0]!}已重启项目`,
    // 设备退场
    device_exit: `${args[0]!}退场调度已完成`,
    // 运输确认
    transport_confirm_way: `${args[0]!}已确认运输车`,
    // 运输车出发
    transport_trailer_go: `${args[0]!}已出发`,
    // 运输车到达
    transport_reach: `${args[0]!}已到达`,
  };
  const msg = eventMap[eventType as keyof typeof eventMap];
  return msg;
};
