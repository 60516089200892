export class EquipmentMaintenanceResp {
  /** 报修待确认 */
  reportToBeConfirmedList: ReportToBeConfirmed[];

  /** 故障报警待确认 */
  alarmToBeConfirmedList: AlarmToBeConfirmed[];

  /** 待维修 */
  toBeRepairedList: ToBeRepaired[];

  /** 设备保养倒计时 */
  equipmentMaintenanceCountdownList: EquipmentMaintenanceCountdown[];

  constructor(data: Omit<EquipmentMaintenanceResp, 'toJson'>) {
    this.reportToBeConfirmedList = data.reportToBeConfirmedList;
    this.alarmToBeConfirmedList = data.alarmToBeConfirmedList;
    this.toBeRepairedList = data.toBeRepairedList;
    this.equipmentMaintenanceCountdownList =
      data.equipmentMaintenanceCountdownList;
  }

  static fromJson(json: any): EquipmentMaintenanceResp {
    return new EquipmentMaintenanceResp({
      reportToBeConfirmedList:
        json['report_to_be_confirmed_list'] != null
          ? (
              json['report_to_be_confirmed_list'] as any[]
            ).map<ReportToBeConfirmed>((v: any) =>
              ReportToBeConfirmed.fromJson(v),
            )
          : [],
      alarmToBeConfirmedList:
        json['alarm_to_be_confirmed_list'] != null
          ? (
              json['alarm_to_be_confirmed_list'] as any[]
            ).map<AlarmToBeConfirmed>((v: any) =>
              AlarmToBeConfirmed.fromJson(v),
            )
          : [],
      toBeRepairedList:
        json['to_be_repaired_list'] != null
          ? (json['to_be_repaired_list'] as any[]).map<ToBeRepaired>((v: any) =>
              ToBeRepaired.fromJson(v),
            )
          : [],
      equipmentMaintenanceCountdownList:
        json['equipment_maintenance_countdown_list'] != null
          ? (
              json['equipment_maintenance_countdown_list'] as any[]
            ).map<EquipmentMaintenanceCountdown>((v: any) =>
              EquipmentMaintenanceCountdown.fromJson(v),
            )
          : [],
    });
  }

  toJson(): any {
    return {
      report_to_be_confirmed_list: this.reportToBeConfirmedList.map(
        (e: ReportToBeConfirmed) => e.toJson(),
      ),
      alarm_to_be_confirmed_list: this.alarmToBeConfirmedList.map(
        (e: AlarmToBeConfirmed) => e.toJson(),
      ),
      to_be_repaired_list: this.toBeRepairedList.map((e: ToBeRepaired) =>
        e.toJson(),
      ),
      equipment_maintenance_countdown_list:
        this.equipmentMaintenanceCountdownList.map(
          (e: EquipmentMaintenanceCountdown) => e.toJson(),
        ),
    };
  }
}
export class AlarmToBeConfirmed {
  /** 地区 */
  region: string;

  /** 设备名称 */
  deviceName: string;

  /** 项目名称 */
  projectName: string;

  /** 报警内容 */
  alarmContent: string;

  constructor(data: Omit<AlarmToBeConfirmed, 'toJson'>) {
    this.region = data.region;
    this.deviceName = data.deviceName;
    this.projectName = data.projectName;
    this.alarmContent = data.alarmContent;
  }

  static fromJson(json: any): AlarmToBeConfirmed {
    return new AlarmToBeConfirmed({
      region: json['region'],
      deviceName: json['device_name'],
      projectName: json['project_name'],
      alarmContent: json['alarm_content'],
    });
  }

  toJson(): any {
    return {
      region: this.region,
      device_name: this.deviceName,
      project_name: this.projectName,
      alarm_content: this.alarmContent,
    };
  }
}
export class EquipmentMaintenanceCountdown {
  /** 地区 */
  region: string;

  /** 设备名称 */
  deviceName: string;

  /** 保养周期 */
  maintenancePeriod: string;

  /** 当前工作时长 */
  currentWorkingHours: string;

  /** 上次保养时工作时长 */
  lastMaintenanceHours: string;

  /** 保养倒计时 */
  maintenanceCountdown: string;

  constructor(data: Omit<EquipmentMaintenanceCountdown, 'toJson'>) {
    this.region = data.region;
    this.deviceName = data.deviceName;
    this.maintenancePeriod = data.maintenancePeriod;
    this.currentWorkingHours = data.currentWorkingHours;
    this.lastMaintenanceHours = data.lastMaintenanceHours;
    this.maintenanceCountdown = data.maintenanceCountdown;
  }

  static fromJson(json: any): EquipmentMaintenanceCountdown {
    return new EquipmentMaintenanceCountdown({
      region: json['region'],
      deviceName: json['device_name'],
      maintenancePeriod: json['maintenance_period'],
      currentWorkingHours: json['current_working_hours'],
      lastMaintenanceHours: json['last_maintenance_hours'],
      maintenanceCountdown: json['maintenance_countdown'],
    });
  }

  toJson(): any {
    return {
      region: this.region,
      device_name: this.deviceName,
      maintenance_period: this.maintenancePeriod,
      current_working_hours: this.currentWorkingHours,
      last_maintenance_hours: this.lastMaintenanceHours,
      maintenance_countdown: this.maintenanceCountdown,
    };
  }
}

export class DeviceDispatchResp {
  /** 近1月设备调度情况 */
  recentDispatch: RecentDispatch[];

  /** 待进场调度 */
  pendingEntry: PendingEntryDispatch[];

  /** 待退场调度 */
  pendingExit: PendingExitDispatch[];

  /** 待运输 */
  pendingTransport: PendingTransport[];

  constructor(data: Omit<DeviceDispatchResp, 'toJson'>) {
    this.recentDispatch = data.recentDispatch;
    this.pendingEntry = data.pendingEntry;
    this.pendingExit = data.pendingExit;
    this.pendingTransport = data.pendingTransport;
  }

  static fromJson(json: any): DeviceDispatchResp {
    return new DeviceDispatchResp({
      recentDispatch:
        json['recentDispatch'] != null
          ? (json['recentDispatch'] as any[]).map<RecentDispatch>((v: any) =>
              RecentDispatch.fromJson(v),
            )
          : [],
      pendingEntry:
        json['pendingEntry'] != null
          ? (json['pendingEntry'] as any[]).map<PendingEntryDispatch>(
              (v: any) => PendingEntryDispatch.fromJson(v),
            )
          : [],
      pendingExit:
        json['pendingExit'] != null
          ? (json['pendingExit'] as any[]).map<PendingExitDispatch>((v: any) =>
              PendingExitDispatch.fromJson(v),
            )
          : [],
      pendingTransport:
        json['pendingTransport'] != null
          ? (json['pendingTransport'] as any[]).map<PendingTransport>(
              (v: any) => PendingTransport.fromJson(v),
            )
          : [],
    });
  }

  toJson(): any {
    return {
      recentDispatch: this.recentDispatch.map((e: RecentDispatch) =>
        e.toJson(),
      ),
      pendingEntry: this.pendingEntry.map((e: PendingEntryDispatch) =>
        e.toJson(),
      ),
      pendingExit: this.pendingExit.map((e: PendingExitDispatch) => e.toJson()),
      pendingTransport: this.pendingTransport.map((e: PendingTransport) =>
        e.toJson(),
      ),
    };
  }
}

export class ToBeRepaired {
  /** 地区 */
  region: string;

  /** 设备名称 */
  deviceName: string;

  /** 故障编号 */
  faultNumber: string;

  /** 故障内容 */
  faultContent: string;

  /** 维修人员 */
  repairPerson: string;

  constructor(data: Omit<ToBeRepaired, 'toJson'>) {
    this.region = data.region;
    this.deviceName = data.deviceName;
    this.faultNumber = data.faultNumber;
    this.faultContent = data.faultContent;
    this.repairPerson = data.repairPerson;
  }

  static fromJson(json: any): ToBeRepaired {
    return new ToBeRepaired({
      region: json['region'],
      deviceName: json['device_name'],
      faultNumber: json['fault_number'],
      faultContent: json['fault_content'],
      repairPerson: json['repair_person'],
    });
  }

  toJson(): any {
    return {
      region: this.region,
      device_name: this.deviceName,
      fault_number: this.faultNumber,
      fault_content: this.faultContent,
      repair_person: this.repairPerson,
    };
  }
}

export class ReportToBeConfirmed {
  /** 地区 */
  region: string;

  /** 设备名称 */
  deviceName: string;

  /** 项目名称 */
  projectName: string;

  /** 等级 */
  level: string;

  /** 报修人 */
  reporter: string;

  constructor(data: Omit<ReportToBeConfirmed, 'toJson'>) {
    this.region = data.region;
    this.deviceName = data.deviceName;
    this.projectName = data.projectName;
    this.level = data.level;
    this.reporter = data.reporter;
  }

  static fromJson(json: any): ReportToBeConfirmed {
    return new ReportToBeConfirmed({
      region: json['region'],
      deviceName: json['device_name'],
      projectName: json['project_name'],
      level: json['level'],
      reporter: json['reporter'],
    });
  }

  toJson(): any {
    return {
      region: this.region,
      device_name: this.deviceName,
      project_name: this.projectName,
      level: this.level,
      reporter: this.reporter,
    };
  }
}

export class RentalOrderResp {
  /** 订单总数 */
  totalCount: number;

  /** 设备需求总数 */
  deviceCount: number;

  /** 近1月订单 */
  recentOrders: RecentOrders[];

  /** 近1月设备需求数 */
  recentDeviceDemands: RecentDeviceDemands[];

  /** 待对接订单 */
  pendingOrders: OrderInfo[];

  /** 待确认订单 */
  pendingConfirmationOrders: OrderInfo[];

  /** 待签署合同 */
  pendingContractSigning: ProjectInfo[];

  /** 待确认结算单 */
  pendingConfirmationSettlements: ProjectInfo[];

  /** 待评价 */
  pendingEvaluation: ProjectInfo[];

  constructor(data: Omit<RentalOrderResp, 'toJson'>) {
    this.totalCount = data.totalCount;
    this.deviceCount = data.deviceCount;
    this.recentOrders = data.recentOrders;
    this.recentDeviceDemands = data.recentDeviceDemands;
    this.pendingOrders = data.pendingOrders;
    this.pendingConfirmationOrders = data.pendingConfirmationOrders;
    this.pendingContractSigning = data.pendingContractSigning;
    this.pendingConfirmationSettlements = data.pendingConfirmationSettlements;
    this.pendingEvaluation = data.pendingEvaluation;
  }

  static fromJson(json: any): RentalOrderResp {
    return new RentalOrderResp({
      totalCount: json['total_count'],
      deviceCount: json['device_count'],
      recentOrders:
        json['recent_orders'] != null
          ? (json['recent_orders'] as any[]).map<RecentOrders>((v: any) =>
              RecentOrders.fromJson(v),
            )
          : [],
      recentDeviceDemands:
        json['recent_device_demands'] != null
          ? (json['recent_device_demands'] as any[]).map<RecentDeviceDemands>(
              (v: any) => RecentDeviceDemands.fromJson(v),
            )
          : [],
      pendingOrders:
        json['pending_orders'] != null
          ? (json['pending_orders'] as any[]).map<OrderInfo>((v: any) =>
              OrderInfo.fromJson(v),
            )
          : [],
      pendingConfirmationOrders:
        json['pending_confirmation_orders'] != null
          ? (json['pending_confirmation_orders'] as any[]).map<OrderInfo>(
              (v: any) => OrderInfo.fromJson(v),
            )
          : [],
      pendingContractSigning:
        json['pending_contract_signing'] != null
          ? (json['pending_contract_signing'] as any[]).map<ProjectInfo>(
              (v: any) => ProjectInfo.fromJson(v),
            )
          : [],
      pendingConfirmationSettlements:
        json['pending_confirmation_settlements'] != null
          ? (
              json['pending_confirmation_settlements'] as any[]
            ).map<ProjectInfo>((v: any) => ProjectInfo.fromJson(v))
          : [],
      pendingEvaluation:
        json['pending_evaluation'] != null
          ? (json['pending_evaluation'] as any[]).map<ProjectInfo>((v: any) =>
              ProjectInfo.fromJson(v),
            )
          : [],
    });
  }

  toJson(): any {
    return {
      total_count: this.totalCount,
      device_count: this.deviceCount,
      recent_orders: this.recentOrders.map((e: RecentOrders) => e.toJson()),
      recent_device_demands: this.recentDeviceDemands.map(
        (e: RecentDeviceDemands) => e.toJson(),
      ),
      pending_orders: this.pendingOrders.map((e: OrderInfo) => e.toJson()),
      pending_confirmation_orders: this.pendingConfirmationOrders.map(
        (e: OrderInfo) => e.toJson(),
      ),
      pending_contract_signing: this.pendingContractSigning.map(
        (e: ProjectInfo) => e.toJson(),
      ),
      pending_confirmation_settlements: this.pendingConfirmationSettlements.map(
        (e: ProjectInfo) => e.toJson(),
      ),
      pending_evaluation: this.pendingEvaluation.map((e: ProjectInfo) =>
        e.toJson(),
      ),
    };
  }
}

export class RecentOrders {
  /** 地区 */
  region: string;

  /** 数量 */
  quantity: number;

  constructor(data: Omit<RecentOrders, 'toJson'>) {
    this.region = data.region;
    this.quantity = data.quantity;
  }

  static fromJson(json: any): RecentOrders {
    return new RecentOrders({
      region: json['region'],
      quantity: json['quantity'],
    });
  }

  toJson(): any {
    return {
      region: this.region,
      quantity: this.quantity,
    };
  }
}

export class RecentDeviceDemands {
  /** 地区 */
  region: string;

  /** 设备信息 */
  devices: Devices[];

  constructor(data: Omit<RecentDeviceDemands, 'toJson'>) {
    this.region = data.region;
    this.devices = data.devices;
  }

  static fromJson(json: any): RecentDeviceDemands {
    return new RecentDeviceDemands({
      region: json['region'],
      devices:
        json['devices'] != null
          ? (json['devices'] as any[]).map<Devices>((v: any) =>
              Devices.fromJson(v),
            )
          : [],
    });
  }

  toJson(): any {
    return {
      region: this.region,
      devices: this.devices.map((e: Devices) => e.toJson()),
    };
  }
}

export class Devices {
  /** 设备类型 */
  deviceType: string;

  /** 需求数 */
  demandCount: number;

  constructor(data: Omit<Devices, 'toJson'>) {
    this.deviceType = data.deviceType;
    this.demandCount = data.demandCount;
  }

  static fromJson(json: any): Devices {
    return new Devices({
      deviceType: json['device_type'],
      demandCount: json['demand_count'],
    });
  }

  toJson(): any {
    return {
      device_type: this.deviceType,
      demand_count: this.demandCount,
    };
  }
}

export class OrderInfo {
  /** 地区 */
  region: string;

  /** 客户名称 */
  customerName: string;

  /** 施工地点 */
  constructionSite: string;

  /** 租赁时间 */
  leaseTime: string;

  /** 设备需求 */
  deviceDemand: string;

  /** 业务员 */
  salesman: string;

  constructor(data: Omit<OrderInfo, 'toJson'>) {
    this.region = data.region;
    this.customerName = data.customerName;
    this.constructionSite = data.constructionSite;
    this.leaseTime = data.leaseTime;
    this.deviceDemand = data.deviceDemand;
    this.salesman = data.salesman;
  }

  static fromJson(json: any): OrderInfo {
    return new OrderInfo({
      region: json['region'],
      customerName: json['customer_name'],
      constructionSite: json['construction_site'],
      leaseTime: json['lease_time'],
      deviceDemand: json['device_demand'],
      salesman: json['salesman'],
    });
  }

  toJson(): any {
    return {
      region: this.region,
      customer_name: this.customerName,
      construction_site: this.constructionSite,
      lease_time: this.leaseTime,
      device_demand: this.deviceDemand,
      salesman: this.salesman,
    };
  }
}

export class ProjectInfo {
  /** 地区 */
  region: string;

  /** 客户名称 */
  customerName: string;

  /** 项目名称 */
  projectName: string;

  constructor(data: Omit<ProjectInfo, 'toJson'>) {
    this.region = data.region;
    this.customerName = data.customerName;
    this.projectName = data.projectName;
  }

  static fromJson(json: any): ProjectInfo {
    return new ProjectInfo({
      region: json['region'],
      customerName: json['customer_name'],
      projectName: json['project_name'],
    });
  }

  toJson(): any {
    return {
      region: this.region,
      customer_name: this.customerName,
      project_name: this.projectName,
    };
  }
}

export class RegionOverview {
  /** 地区 */
  region: string;

  /** 数量 */
  quantity: number;

  constructor(data: Omit<RegionOverview, 'toJson'>) {
    this.region = data.region;
    this.quantity = data.quantity;
  }

  static fromJson(json: any): RegionOverview {
    return new RegionOverview({
      region: json['region'],
      quantity: json['quantity'],
    });
  }

  toJson(): any {
    return {
      region: this.region,
      quantity: this.quantity,
    };
  }
}

export class RecentYearProjectTrend {
  /** 月份 */
  month: string;

  /** 地区概况 */
  regionOverview: RegionOverview[];

  constructor(data: Omit<RecentYearProjectTrend, 'toJson'>) {
    this.month = data.month;
    this.regionOverview = data.regionOverview;
  }

  static fromJson(json: any): RecentYearProjectTrend {
    return new RecentYearProjectTrend({
      month: json['month'],
      regionOverview:
        json['region_overview'] != null
          ? (json['region_overview'] as any[]).map<RegionOverview>((v: any) =>
              RegionOverview.fromJson(v),
            )
          : [],
    });
  }

  toJson(): any {
    return {
      month: this.month,
      region_overview: this.regionOverview.map((e: RegionOverview) =>
        e.toJson(),
      ),
    };
  }
}

export class RecentMonthProjectOverview {
  /** 状态 */
  status: string;

  /** 地区概况 */
  regionOverview: RegionOverview[];

  constructor(data: Omit<RecentMonthProjectOverview, 'toJson'>) {
    this.status = data.status;
    this.regionOverview = data.regionOverview;
  }

  static fromJson(json: any): RecentMonthProjectOverview {
    return new RecentMonthProjectOverview({
      status: json['status'],
      regionOverview:
        json['region_overview'] != null
          ? (json['region_overview'] as any[]).map<RegionOverview>((v: any) =>
              RegionOverview.fromJson(v),
            )
          : [],
    });
  }

  toJson(): any {
    return {
      status: this.status,
      region_overview: this.regionOverview.map((e: RegionOverview) =>
        e.toJson(),
      ),
    };
  }
}

export class RecentDispatch {
  /** 设备 */
  device: string;

  /** 调度状态 */
  dispatchStatus: DispatchStatus[];

  constructor(data: Omit<RecentDispatch, 'toJson'>) {
    this.device = data.device;
    this.dispatchStatus = data.dispatchStatus;
  }

  static fromJson(json: any): RecentDispatch {
    return new RecentDispatch({
      device: json['device'],
      dispatchStatus:
        json['dispatch_status'] != null
          ? (json['dispatch_status'] as any[]).map<DispatchStatus>((v: any) =>
              DispatchStatus.fromJson(v),
            )
          : [],
    });
  }

  toJson(): any {
    return {
      device: this.device,
      dispatch_status: this.dispatchStatus.map((e: DispatchStatus) =>
        e.toJson(),
      ),
    };
  }
}

export class DispatchStatus {
  /** 状态 */
  status: string;

  /** 数量 */
  count: number;

  constructor(data: Omit<DispatchStatus, 'toJson'>) {
    this.status = data.status;
    this.count = data.count;
  }

  static fromJson(json: any): DispatchStatus {
    return new DispatchStatus({
      status: json['status'],
      count: json['count'],
    });
  }

  toJson(): any {
    return {
      status: this.status,
      count: this.count,
    };
  }
}

export class ProjectOverviewResp {
  /** 近1月项目概况 */
  recentMonthProjectOverview: RecentMonthProjectOverview[];

  /** 近1年项目趋势 */
  recentYearProjectTrend: RecentYearProjectTrend[];

  /** 项目动态 */
  projectDynamic: ProjectDynamic[];

  constructor(data: Omit<ProjectOverviewResp, 'toJson'>) {
    this.recentMonthProjectOverview = data.recentMonthProjectOverview;
    this.recentYearProjectTrend = data.recentYearProjectTrend;
    this.projectDynamic = data.projectDynamic;
  }

  static fromJson(json: any): ProjectOverviewResp {
    return new ProjectOverviewResp({
      recentMonthProjectOverview:
        json['recent_month_project_overview'] != null
          ? (
              json['recent_month_project_overview'] as any[]
            ).map<RecentMonthProjectOverview>((v: any) =>
              RecentMonthProjectOverview.fromJson(v),
            )
          : [],
      recentYearProjectTrend:
        json['recent_year_project_trend'] != null
          ? (
              json['recent_year_project_trend'] as any[]
            ).map<RecentYearProjectTrend>((v: any) =>
              RecentYearProjectTrend.fromJson(v),
            )
          : [],
      projectDynamic:
        json['project_dynamic'] != null
          ? (json['project_dynamic'] as any[]).map<ProjectDynamic>((v: any) =>
              ProjectDynamic.fromJson(v),
            )
          : [],
    });
  }

  toJson(): any {
    return {
      recent_month_project_overview: this.recentMonthProjectOverview.map(
        (e: RecentMonthProjectOverview) => e.toJson(),
      ),
      recent_year_project_trend: this.recentYearProjectTrend.map(
        (e: RecentYearProjectTrend) => e.toJson(),
      ),
      project_dynamic: this.projectDynamic.map((e: ProjectDynamic) =>
        e.toJson(),
      ),
    };
  }
}

export class ProjectDynamic {
  /** 地区 */
  region: string;

  /** 项目名称 */
  projectName: string;

  /** 客户名称 */
  customerName: string;

  /** 施工地点 */
  construction: string;

  /** 开始日期 */
  startDate: string;

  /** 业务经理 */
  businessMgr: string;

  /** 项目经理 */
  projectMgr: string;

  /** 动态 */
  dynamic: string;

  constructor(data: Omit<ProjectDynamic, 'toJson'>) {
    this.region = data.region;
    this.projectName = data.projectName;
    this.customerName = data.customerName;
    this.construction = data.construction;
    this.startDate = data.startDate;
    this.businessMgr = data.businessMgr;
    this.projectMgr = data.projectMgr;
    this.dynamic = data.dynamic;
  }

  static fromJson(json: any): ProjectDynamic {
    return new ProjectDynamic({
      region: json['region'],
      projectName: json['project_name'],
      customerName: json['customer_name'],
      construction: json['construction'],
      startDate: json['start_date'],
      businessMgr: json['business_mgr'],
      projectMgr: json['project_mgr'],
      dynamic: json['dynamic'],
    });
  }

  toJson(): any {
    return {
      region: this.region,
      project_name: this.projectName,
      customer_name: this.customerName,
      construction: this.construction,
      start_date: this.startDate,
      business_mgr: this.businessMgr,
      project_mgr: this.projectMgr,
      dynamic: this.dynamic,
    };
  }
}

export class PendingTransport {
  /** 地区 */
  region: string;

  /** 运输物品 */
  item: string;

  /** 始发位置 */
  origin: string;

  /** 终点位置 */
  destination: string;

  /** 运输时间 */
  transport: string;

  /** 申请人 */
  applicant: string;

  constructor(data: Omit<PendingTransport, 'toJson'>) {
    this.region = data.region;
    this.item = data.item;
    this.origin = data.origin;
    this.destination = data.destination;
    this.transport = data.transport;
    this.applicant = data.applicant;
  }

  static fromJson(json: any): PendingTransport {
    return new PendingTransport({
      region: json['region'],
      item: json['item'],
      origin: json['origin'],
      destination: json['destination'],
      transport: json['transport'],
      applicant: json['applicant'],
    });
  }

  toJson(): any {
    return {
      region: this.region,
      item: this.item,
      origin: this.origin,
      destination: this.destination,
      transport: this.transport,
      applicant: this.applicant,
    };
  }
}

export class PendingExitDispatch {
  /** 地区 */
  region: string;

  /** 设备名称 */
  deviceName: string;

  /** 项目名称 */
  projectName: string;

  /** 施工地点 */
  construction: string;

  /** 退场时间 */
  exitTime: string;

  constructor(data: Omit<PendingExitDispatch, 'toJson'>) {
    this.region = data.region;
    this.deviceName = data.deviceName;
    this.projectName = data.projectName;
    this.construction = data.construction;
    this.exitTime = data.exitTime;
  }

  static fromJson(json: any): PendingExitDispatch {
    return new PendingExitDispatch({
      region: json['region'],
      deviceName: json['device_name'],
      projectName: json['project_name'],
      construction: json['construction'],
      exitTime: json['exit_time'],
    });
  }

  toJson(): any {
    return {
      region: this.region,
      device_name: this.deviceName,
      project_name: this.projectName,
      construction: this.construction,
      exit_time: this.exitTime,
    };
  }
}

export class PendingEntryDispatch {
  /** 地区 */
  region: string;

  /** 项目名称 */
  projectName: string;

  /** 施工地点 */
  construction: string;

  /** 设备类型 */
  deviceType: string;

  /** 进场时间 */
  entryTime: string;

  /** 预估工期 */
  estimated: string;

  constructor(data: Omit<PendingEntryDispatch, 'toJson'>) {
    this.region = data.region;
    this.projectName = data.projectName;
    this.construction = data.construction;
    this.deviceType = data.deviceType;
    this.entryTime = data.entryTime;
    this.estimated = data.estimated;
  }

  static fromJson(json: any): PendingEntryDispatch {
    return new PendingEntryDispatch({
      region: json['region'],
      projectName: json['project_name'],
      construction: json['construction'],
      deviceType: json['device_type'],
      entryTime: json['entry_time'],
      estimated: json['estimated'],
    });
  }

  toJson(): any {
    return {
      region: this.region,
      project_name: this.projectName,
      construction: this.construction,
      device_type: this.deviceType,
      entry_time: this.entryTime,
      estimated: this.estimated,
    };
  }
}
export class Project {
  /**  项目id */
  projectId: number;

  /**  项目名 */
  projectName: string;

  /**  项目进度 */
  projectProgress: string;

  projectLocation: Location;

  constructor(data: Omit<Project, 'toJson'>) {
    this.projectId = data.projectId;
    this.projectName = data.projectName;
    this.projectProgress = data.projectProgress;
    this.projectLocation = data.projectLocation;
  }

  static fromJson(json: any): Project {
    return new Project({
      projectId: json['project_id'],
      projectName: json['project_name'],
      projectProgress: json['project_progress'],
      projectLocation: Location.fromJson(json['project_location']),
    });
  }

  toJson(): any {
    return {
      project_id: this.projectId,
      project_name: this.projectName,
      project_progress: this.projectProgress,
      project_location: this.projectLocation.toJson(),
    };
  }
}

export class Location {
  /**  纬度 */
  lat: string;

  /**  精度 */
  lng: string;

  /**  地址 */
  address: string;

  constructor(data: Omit<Location, 'toJson'>) {
    this.lat = data.lat;
    this.lng = data.lng;
    this.address = data.address;
  }

  static fromJson(json: any): Location {
    return new Location({
      lat: json['lat'],
      lng: json['lng'],
      address: json['address'],
    });
  }

  toJson(): any {
    return {
      lat: this.lat,
      lng: this.lng,
      address: this.address,
    };
  }
}

export class DeviceTrajectoryResp {
  /**  经纬度曲线 */
  deviceTrajectory: DeviceTrajectory[];

  constructor(data: Omit<DeviceTrajectoryResp, 'toJson'>) {
    this.deviceTrajectory = data.deviceTrajectory;
  }

  static fromJson(json: any): DeviceTrajectoryResp {
    return new DeviceTrajectoryResp({
      deviceTrajectory:
        json['device_trajectory'] != null
          ? (json['device_trajectory'] as any[]).map<DeviceTrajectory>(
              (v: any) => DeviceTrajectory.fromJson(v),
            )
          : [],
    });
  }

  toJson(): any {
    return {
      device_trajectory: this.deviceTrajectory.map((e: DeviceTrajectory) =>
        e.toJson(),
      ),
    };
  }
}

export class DeviceTrajectory {
  /**  纬度 */
  lat: string;

  /**  经度 */
  lng: string;

  /**  数据时间 */
  dataTime: string;

  constructor(data: Omit<DeviceTrajectory, 'toJson'>) {
    this.lat = data.lat;
    this.lng = data.lng;
    this.dataTime = data.dataTime;
  }

  static fromJson(json: any): DeviceTrajectory {
    return new DeviceTrajectory({
      lat: json['lat'],
      lng: json['lng'],
      dataTime: json['data_time'],
    });
  }

  toJson(): any {
    return {
      lat: this.lat,
      lng: this.lng,
      data_time: this.dataTime,
    };
  }
}

export class DeviceTrajectoryReq {
  /**  项目id */
  projectId: number;

  constructor(data: Omit<DeviceTrajectoryReq, 'toJson'>) {
    this.projectId = data.projectId;
  }

  static fromJson(json: any): DeviceTrajectoryReq {
    return new DeviceTrajectoryReq({
      projectId: json['project_id'],
    });
  }

  toJson(): any {
    return {
      project_id: this.projectId,
    };
  }
}

export class DeviceRecordResp {
  /**  今日工作时长 */
  todayWorkingHours: string;

  /**  今日油耗量 */
  todayOilConsumption: string;

  /**  今日剩余油量 */
  todayOilQuantity: string;

  /**  工时曲线 */
  workingHoursCurve: Curve[];

  /**  油耗曲线 */
  oilConsumptionCurve: Curve[];

  /**  油量曲线 */
  oilQuantityCurve: Curve[];

  /**  经纬度曲线 */
  deviceTrajectory: DeviceTrajectory[];

  constructor(data: Omit<DeviceRecordResp, 'toJson'>) {
    this.todayWorkingHours = data.todayWorkingHours;
    this.todayOilConsumption = data.todayOilConsumption;
    this.todayOilQuantity = data.todayOilQuantity;
    this.workingHoursCurve = data.workingHoursCurve;
    this.oilConsumptionCurve = data.oilConsumptionCurve;
    this.oilQuantityCurve = data.oilQuantityCurve;
    this.deviceTrajectory = data.deviceTrajectory;
  }

  static fromJson(json: any): DeviceRecordResp {
    return new DeviceRecordResp({
      todayWorkingHours: json['today_working_hours'],
      todayOilConsumption: json['today_oil_consumption'],
      todayOilQuantity: json['today_oil_quantity'],
      workingHoursCurve:
        json['working_hours_curve'] != null
          ? (json['working_hours_curve'] as any[]).map<Curve>((v: any) =>
              Curve.fromJson(v),
            )
          : [],
      oilConsumptionCurve:
        json['oil_consumption_curve'] != null
          ? (json['oil_consumption_curve'] as any[]).map<Curve>((v: any) =>
              Curve.fromJson(v),
            )
          : [],
      oilQuantityCurve:
        json['oil_quantity_curve'] != null
          ? (json['oil_quantity_curve'] as any[]).map<Curve>((v: any) =>
              Curve.fromJson(v),
            )
          : [],
      deviceTrajectory:
        json['device_trajectory'] != null
          ? (json['device_trajectory'] as any[]).map<DeviceTrajectory>(
              (v: any) => DeviceTrajectory.fromJson(v),
            )
          : [],
    });
  }

  toJson(): any {
    return {
      today_working_hours: this.todayWorkingHours,
      today_oil_consumption: this.todayOilConsumption,
      today_oil_quantity: this.todayOilQuantity,
      working_hours_curve: this.workingHoursCurve.map((e: Curve) => e.toJson()),
      oil_consumption_curve: this.oilConsumptionCurve.map((e: Curve) =>
        e.toJson(),
      ),
      oil_quantity_curve: this.oilQuantityCurve.map((e: Curve) => e.toJson()),
      device_trajectory: this.deviceTrajectory.map((e: DeviceTrajectory) =>
        e.toJson(),
      ),
    };
  }
}

export class Curve {
  /**  曲线值 */
  value: string;

  /**  时间 */
  dataTime: string;

  constructor(data: Omit<Curve, 'toJson'>) {
    this.value = data.value;
    this.dataTime = data.dataTime;
  }

  static fromJson(json: any): Curve {
    return new Curve({
      value: json['value'],
      dataTime: json['data_time'],
    });
  }

  toJson(): any {
    return {
      value: this.value,
      data_time: this.dataTime,
    };
  }
}

export class DeviceRecordReq {
  /**  设备id */
  deviceId: number;

  constructor(data: Omit<DeviceRecordReq, 'toJson'>) {
    this.deviceId = data.deviceId;
  }

  static fromJson(json: any): DeviceRecordReq {
    return new DeviceRecordReq({
      deviceId: json['device_id'],
    });
  }

  toJson(): any {
    return {
      device_id: this.deviceId,
    };
  }
}

export class ProjectEventResp {
  /**  事件类型 */
  eventType: string;

  /**  事件内容 */
  eventContent: string;

  /**  事件事件 */
  eventTime: string;

  constructor(data: Omit<ProjectEventResp, 'toJson'>) {
    this.eventType = data.eventType;
    this.eventContent = data.eventContent;
    this.eventTime = data.eventTime;
  }

  static fromJson(json: any): ProjectEventResp {
    return new ProjectEventResp({
      eventType: json['event_type'],
      eventContent: json['event_content'],
      eventTime: json['event_time'],
    });
  }

  toJson(): any {
    return {
      event_type: this.eventType,
      event_content: this.eventContent,
      event_time: this.eventTime,
    };
  }
}

export class ProjectEventReq {
  /**  项目id */
  projectId: number;

  constructor(data: Omit<ProjectEventReq, 'toJson'>) {
    this.projectId = data.projectId;
  }

  static fromJson(json: any): ProjectEventReq {
    return new ProjectEventReq({
      projectId: json['project_id'],
    });
  }

  toJson(): any {
    return {
      project_id: this.projectId,
    };
  }
}

export class DeviceDetailResp {
  /**  设备id */
  deviceId: number;

  /**  设备名 */
  deviceName: string;

  /**  设备类型 */
  deviceType: string;

  /**  驾驶员 */
  driver: Driver[];

  location: Location;

  project: Project;

  constructor(data: Omit<DeviceDetailResp, 'toJson'>) {
    this.deviceId = data.deviceId;
    this.deviceName = data.deviceName;
    this.deviceType = data.deviceType;
    this.driver = data.driver;
    this.location = data.location;
    this.project = data.project;
  }

  static fromJson(json: any): DeviceDetailResp {
    return new DeviceDetailResp({
      deviceId: json['device_id'],
      deviceName: json['device_name'],
      deviceType: json['device_type'],
      driver:
        json['driver'] != null
          ? (json['driver'] as any[]).map<Driver>((v: any) =>
              Driver.fromJson(v),
            )
          : [],
      location: Location.fromJson(json['location']),
      project: Project.fromJson(json['project']),
    });
  }

  toJson(): any {
    return {
      device_id: this.deviceId,
      device_name: this.deviceName,
      device_type: this.deviceType,
      driver: this.driver.map((e: Driver) => e.toJson()),
      location: this.location.toJson(),
      project: this.project.toJson(),
    };
  }
}

export class Driver {
  /**  驾驶员id */
  driverId: number;

  /**  照片 */
  pic: string;

  /**  驾驶员姓名 */
  driverName: string;

  /**  手机 */
  mobile: string;

  /**  头盔状态 */
  helmetStatus: string;

  constructor(data: Omit<Driver, 'toJson'>) {
    this.driverId = data.driverId;
    this.pic = data.pic;
    this.driverName = data.driverName;
    this.mobile = data.mobile;
    this.helmetStatus = data.helmetStatus;
  }

  static fromJson(json: any): Driver {
    return new Driver({
      driverId: json['driver_id'],
      pic: json['pic'],
      driverName: json['driver_name'],
      mobile: json['mobile'],
      helmetStatus: json['helmet_status'],
    });
  }

  toJson(): any {
    return {
      driver_id: this.driverId,
      pic: this.pic,
      driver_name: this.driverName,
      mobile: this.mobile,
      helmet_status: this.helmetStatus,
    };
  }
}

export class DeviceDetailReq {
  /**  设备id */
  deviceId: number;

  constructor(data: Omit<DeviceDetailReq, 'toJson'>) {
    this.deviceId = data.deviceId;
  }

  static fromJson(json: any): DeviceDetailReq {
    return new DeviceDetailReq({
      deviceId: json['device_id'],
    });
  }

  toJson(): any {
    return {
      device_id: this.deviceId,
    };
  }
}

export class LocationResp {
  /**  省份名称 */
  province: string;

  /**  城市名称 */
  city: string;

  /**  城市的adcode编码 */
  adCode: string;

  /**  所在城市矩形区域范围 */
  rectangle: string;

  constructor(data: Omit<LocationResp, 'toJson'>) {
    this.province = data.province;
    this.city = data.city;
    this.adCode = data.adCode;
    this.rectangle = data.rectangle;
  }

  static fromJson(json: any): LocationResp {
    return new LocationResp({
      province: json['province'],
      city: json['city'],
      adCode: json['ad_code'],
      rectangle: json['rectangle'],
    });
  }

  toJson(): any {
    return {
      province: this.province,
      city: this.city,
      ad_code: this.adCode,
      rectangle: this.rectangle,
    };
  }
}

export class LocationReq {
  /**  ip地址 */
  ip?: string;

  constructor(data: Omit<LocationReq, 'toJson'>) {
    this.ip = data.ip;
  }

  static fromJson(json: any): LocationReq {
    return new LocationReq({
      ip: json['ip'],
    });
  }

  toJson(): any {
    return {
      ip: this.ip,
    };
  }
}

export class ReturnVisit {
  /**  回访id */
  id: number;

  /**  回访结果类型 */
  type: string;

  /**  内容 */
  content: string;

  /**  回访时间 */
  visitTime: string;

  constructor(data: Omit<ReturnVisit, 'toJson'>) {
    this.id = data.id;
    this.type = data.type;
    this.content = data.content;
    this.visitTime = data.visitTime;
  }

  static fromJson(json: any): ReturnVisit {
    return new ReturnVisit({
      id: json['id'],
      type: json['type'],
      content: json['content'],
      visitTime: json['visit_time'],
    });
  }

  toJson(): any {
    return {
      id: this.id,
      type: this.type,
      content: this.content,
      visit_time: this.visitTime,
    };
  }
}

export class Complaint {
  /**  投诉id */
  id: number;

  /**  投诉类型 */
  type: string;

  /**  投诉内容 */
  content: string;

  /**  投诉时间 */
  complaintTime: string;

  constructor(data: Omit<Complaint, 'toJson'>) {
    this.id = data.id;
    this.type = data.type;
    this.content = data.content;
    this.complaintTime = data.complaintTime;
  }

  static fromJson(json: any): Complaint {
    return new Complaint({
      id: json['id'],
      type: json['type'],
      content: json['content'],
      complaintTime: json['complaint_time'],
    });
  }

  toJson(): any {
    return {
      id: this.id,
      type: this.type,
      content: this.content,
      complaint_time: this.complaintTime,
    };
  }
}

export class WorkCurveResp {
  /**  工时曲线 */
  workingHoursCurve: Curve[];

  /**  油耗曲线 */
  oilConsumptionCurve: Curve[];

  constructor(data: Omit<WorkCurveResp, 'toJson'>) {
    this.workingHoursCurve = data.workingHoursCurve;
    this.oilConsumptionCurve = data.oilConsumptionCurve;
  }

  static fromJson(json: any): WorkCurveResp {
    return new WorkCurveResp({
      workingHoursCurve:
        json['working_hours_curve'] != null
          ? (json['working_hours_curve'] as any[]).map<Curve>((v: any) =>
              Curve.fromJson(v),
            )
          : [],
      oilConsumptionCurve:
        json['oil_consumption_curve'] != null
          ? (json['oil_consumption_curve'] as any[]).map<Curve>((v: any) =>
              Curve.fromJson(v),
            )
          : [],
    });
  }

  toJson(): any {
    return {
      working_hours_curve: this.workingHoursCurve.map((e: Curve) => e.toJson()),
      oil_consumption_curve: this.oilConsumptionCurve.map((e: Curve) =>
        e.toJson(),
      ),
    };
  }
}

export class WorkCurveReq {
  /**  项目id */
  projectId: number;

  constructor(data: Omit<WorkCurveReq, 'toJson'>) {
    this.projectId = data.projectId;
  }

  static fromJson(json: any): WorkCurveReq {
    return new WorkCurveReq({
      projectId: json['project_id'],
    });
  }

  toJson(): any {
    return {
      project_id: this.projectId,
    };
  }
}

export class ProjectDetailResp {
  /**  项目id */
  id: number;

  /**  项目名 */
  projectName: string;

  /**  项目状态 */
  status: string;

  /**  项目进度 */
  progress: string;

  /**  所属区域 */
  area: string;

  /**  客户单位 */
  lesseeName: string;

  location: Location;

  /**  预估工期 */
  period: number;

  /**  开始日期 */
  startDate: string;

  /**  预估费用 */
  cost: string;

  projectManager: Manager;

  businessManager: Manager;

  constructor(data: Omit<ProjectDetailResp, 'toJson'>) {
    this.id = data.id;
    this.projectName = data.projectName;
    this.status = data.status;
    this.progress = data.progress;
    this.area = data.area;
    this.lesseeName = data.lesseeName;
    this.location = data.location;
    this.period = data.period;
    this.startDate = data.startDate;
    this.cost = data.cost;
    this.projectManager = data.projectManager;
    this.businessManager = data.businessManager;
  }

  static fromJson(json: any): ProjectDetailResp {
    return new ProjectDetailResp({
      id: json['id'],
      projectName: json['project_name'],
      status: json['status'],
      progress: json['progress'],
      area: json['area'],
      lesseeName: json['lessee_name'],
      location: Location.fromJson(json['location']),
      period: json['period'],
      startDate: json['start_date'],
      cost: json['cost'],
      projectManager: Manager.fromJson(json['project_manager']),
      businessManager: Manager.fromJson(json['business_manager']),
    });
  }

  toJson(): any {
    return {
      id: this.id,
      project_name: this.projectName,
      status: this.status,
      progress: this.progress,
      area: this.area,
      lessee_name: this.lesseeName,
      location: this.location.toJson(),
      period: this.period,
      start_date: this.startDate,
      cost: this.cost,
      project_manager: this.projectManager.toJson(),
      business_manager: this.businessManager.toJson(),
    };
  }
}

export class Manager {
  /**  姓名 */
  name: string;

  /**  电话 */
  mobile: string;

  /**  照片 */
  photo: string;

  constructor(data: Omit<Manager, 'toJson'>) {
    this.name = data.name;
    this.mobile = data.mobile;
    this.photo = data.photo;
  }

  static fromJson(json: any): Manager {
    return new Manager({
      name: json['name'],
      mobile: json['mobile'],
      photo: json['photo'],
    });
  }

  toJson(): any {
    return {
      name: this.name,
      mobile: this.mobile,
      photo: this.photo,
    };
  }
}

export class ProjectDetailReq {
  /**  项目id */
  projectId: number;

  constructor(data: Omit<ProjectDetailReq, 'toJson'>) {
    this.projectId = data.projectId;
  }

  static fromJson(json: any): ProjectDetailReq {
    return new ProjectDetailReq({
      projectId: json['project_id'],
    });
  }

  toJson(): any {
    return {
      project_id: this.projectId,
    };
  }
}

export class DeviceResp {
  /**  设备id */
  deviceId: number;

  /**  设备名 */
  deviceName: string;

  /**  图片 */
  pic: string;

  location: Location;

  /**  设备状态 */
  deviceStatus: string;

  /**  驾驶员 */
  driver: Driver[];

  constructor(data: Omit<DeviceResp, 'toJson'>) {
    this.deviceId = data.deviceId;
    this.deviceName = data.deviceName;
    this.pic = data.pic;
    this.location = data.location;
    this.deviceStatus = data.deviceStatus;
    this.driver = data.driver;
  }

  static fromJson(json: any): DeviceResp {
    return new DeviceResp({
      deviceId: json['device_id'],
      deviceName: json['device_name'],
      pic: json['pic'],
      location: Location.fromJson(json['location']),
      deviceStatus: json['device_status'],
      driver:
        json['driver'] != null
          ? (json['driver'] as any[]).map<Driver>((v: any) =>
              Driver.fromJson(v),
            )
          : [],
    });
  }

  toJson(): any {
    return {
      device_id: this.deviceId,
      device_name: this.deviceName,
      pic: this.pic,
      location: this.location.toJson(),
      device_status: this.deviceStatus,
      driver: this.driver.map((e: Driver) => e.toJson()),
    };
  }
}

export class DeviceReq {
  /**  项目id */
  projectId: number;

  constructor(data: Omit<DeviceReq, 'toJson'>) {
    this.projectId = data.projectId;
  }

  static fromJson(json: any): DeviceReq {
    return new DeviceReq({
      projectId: json['project_id'],
    });
  }

  toJson(): any {
    return {
      project_id: this.projectId,
    };
  }
}

export class ComplaintsDistribution {
  /**  投诉类型 */
  complaintsType: string;

  /**  投诉数量 */
  complaintsCount: number;

  constructor(data: Omit<ComplaintsDistribution, 'toJson'>) {
    this.complaintsType = data.complaintsType;
    this.complaintsCount = data.complaintsCount;
  }

  static fromJson(json: any): ComplaintsDistribution {
    return new ComplaintsDistribution({
      complaintsType: json['complaints_type'],
      complaintsCount: json['complaints_count'],
    });
  }

  toJson(): any {
    return {
      complaints_type: this.complaintsType,
      complaints_count: this.complaintsCount,
    };
  }
}

export class ServiceQualityResp {
  /**  近一个月综合评分 */
  compositeScore: string;

  /**  近一个月投诉数量 */
  complaintsCount: number;

  /**  投诉分布 */
  complaintsDistribution: ComplaintsDistribution[];

  constructor(data: Omit<ServiceQualityResp, 'toJson'>) {
    this.compositeScore = data.compositeScore;
    this.complaintsCount = data.complaintsCount;
    this.complaintsDistribution = data.complaintsDistribution;
  }

  static fromJson(json: any): ServiceQualityResp {
    return new ServiceQualityResp({
      compositeScore: json['composite_score'],
      complaintsCount: json['complaints_count'],
      complaintsDistribution:
        json['complaints_distribution'] != null
          ? (
              json['complaints_distribution'] as any[]
            ).map<ComplaintsDistribution>((v: any) =>
              ComplaintsDistribution.fromJson(v),
            )
          : [],
    });
  }

  toJson(): any {
    return {
      composite_score: this.compositeScore,
      complaints_count: this.complaintsCount,
      complaints_distribution: this.complaintsDistribution.map(
        (e: ComplaintsDistribution) => e.toJson(),
      ),
    };
  }
}

export class ServiceQualityReq {
  /**  省份 */
  province: string;

  constructor(data: Omit<ServiceQualityReq, 'toJson'>) {
    this.province = data.province;
  }

  static fromJson(json: any): ServiceQualityReq {
    return new ServiceQualityReq({
      province: json['province'],
    });
  }

  toJson(): any {
    return {
      province: this.province,
    };
  }
}

export class ScheduleListResp {
  /**  设备id */
  deviceId: number;

  /**  项目id */
  projectId: number;

  constructor(data: Omit<ScheduleListResp, 'toJson'>) {
    this.deviceId = data.deviceId;
    this.projectId = data.projectId;
  }

  static fromJson(json: any): ScheduleListResp {
    return new ScheduleListResp({
      deviceId: json['device_id'],
      projectId: json['project_id'],
    });
  }

  toJson(): any {
    return {
      device_id: this.deviceId,
      project_id: this.projectId,
    };
  }
}

export class ScheduleListReq {
  /**  省份 */
  province?: string;

  constructor(data: Omit<ScheduleListReq, 'toJson'>) {
    this.province = data.province;
  }

  static fromJson(json: any): ScheduleListReq {
    return new ScheduleListReq({
      province: json['province'],
    });
  }

  toJson(): any {
    return {
      province: this.province,
    };
  }
}

export class ProvinceOverviewResp {
  /**  省份 */
  province: string;

  /**  项目数量 */
  projectCount: number;

  /**  设备数量 */
  deviceCount: number;

  constructor(data: Omit<ProvinceOverviewResp, 'toJson'>) {
    this.province = data.province;
    this.projectCount = data.projectCount;
    this.deviceCount = data.deviceCount;
  }

  static fromJson(json: any): ProvinceOverviewResp {
    return new ProvinceOverviewResp({
      province: json['province'],
      projectCount: json['project_count'],
      deviceCount: json['device_count'],
    });
  }

  toJson(): any {
    return {
      province: this.province,
      project_count: this.projectCount,
      device_count: this.deviceCount,
    };
  }
}

export class ProvinceOverviewReq {
  constructor(data: Omit<ProvinceOverviewReq, 'toJson'>) {}

  static fromJson(json: any): ProvinceOverviewReq {
    return new ProvinceOverviewReq({});
  }

  toJson(): any {
    return {};
  }
}

export class ProjectListResp {
  /**  项目id */
  projectId: number;

  /**  项目名 */
  projectName: string;

  /**  项目进度 */
  projectProgress: string;

  location: Location;

  constructor(data: Omit<ProjectListResp, 'toJson'>) {
    this.projectId = data.projectId;
    this.projectName = data.projectName;
    this.projectProgress = data.projectProgress;
    this.location = data.location;
  }

  static fromJson(json: any): ProjectListResp {
    return new ProjectListResp({
      projectId: json['project_id'],
      projectName: json['project_name'],
      projectProgress: json['project_progress'],
      location: Location.fromJson(json['location']),
    });
  }

  toJson(): any {
    return {
      project_id: this.projectId,
      project_name: this.projectName,
      project_progress: this.projectProgress,
      location: this.location.toJson(),
    };
  }
}

export class ProjectListReq {
  /**  省份 */
  province?: string;

  constructor(data: Omit<ProjectListReq, 'toJson'>) {
    this.province = data.province;
  }

  static fromJson(json: any): ProjectListReq {
    return new ProjectListReq({
      province: json['province'],
    });
  }

  toJson(): any {
    return {
      province: this.province,
    };
  }
}

export class NationalOverviewResp {
  /**  项目数量 */
  projectCount: number;

  /**  设备数量 */
  deviceCount: number;

  /**  工作时长 */
  workHours: string;

  constructor(data: Omit<NationalOverviewResp, 'toJson'>) {
    this.projectCount = data.projectCount;
    this.deviceCount = data.deviceCount;
    this.workHours = data.workHours;
  }

  static fromJson(json: any): NationalOverviewResp {
    return new NationalOverviewResp({
      projectCount: json['project_count'],
      deviceCount: json['device_count'],
      workHours: json['work_hours'],
    });
  }

  toJson(): any {
    return {
      project_count: this.projectCount,
      device_count: this.deviceCount,
      work_hours: this.workHours,
    };
  }
}

export class NationalOverviewReq {
  constructor(data: Omit<NationalOverviewReq, 'toJson'>) {}

  static fromJson(json: any): NationalOverviewReq {
    return new NationalOverviewReq({});
  }

  toJson(): any {
    return {};
  }
}

export class DeviceTypeAttendance {
  /**  设备类型 */
  deviceType: string;

  /**  设备出勤率 */
  attendance: string;

  constructor(data: Omit<DeviceTypeAttendance, 'toJson'>) {
    this.deviceType = data.deviceType;
    this.attendance = data.attendance;
  }

  static fromJson(json: any): DeviceTypeAttendance {
    return new DeviceTypeAttendance({
      deviceType: json['device_type'],
      attendance: json['attendance'],
    });
  }

  toJson(): any {
    return {
      device_type: this.deviceType,
      attendance: this.attendance,
    };
  }
}

export class DeviceListResp {
  /**  设备id */
  deviceId: number;

  /**  设备名 */
  deviceName: string;

  /**  设备类型 */
  deviceType: string;

  /**  设备型号 */
  deviceModel: string;

  /**  图片 */
  pic: string;

  /**  施工状态（STATIC:静止,RUNNING:运行） */
  constructionStatus: string;

  location: Location;

  constructor(data: Omit<DeviceListResp, 'toJson'>) {
    this.deviceId = data.deviceId;
    this.deviceName = data.deviceName;
    this.deviceType = data.deviceType;
    this.deviceModel = data.deviceModel;
    this.pic = data.pic;
    this.constructionStatus = data.constructionStatus;
    this.location = data.location;
  }

  static fromJson(json: any): DeviceListResp {
    return new DeviceListResp({
      deviceId: json['device_id'],
      deviceName: json['device_name'],
      deviceType: json['device_type'],
      deviceModel: json['device_model'],
      pic: json['pic'],
      constructionStatus: json['construction_status'],
      location: Location.fromJson(json['location']),
    });
  }

  toJson(): any {
    return {
      device_id: this.deviceId,
      device_name: this.deviceName,
      device_type: this.deviceType,
      device_model: this.deviceModel,
      pic: this.pic,
      construction_status: this.constructionStatus,
      location: this.location.toJson(),
    };
  }
}

export class DeviceListReq {
  /**  省份 */
  province?: string;

  constructor(data: Omit<DeviceListReq, 'toJson'>) {
    this.province = data.province;
  }

  static fromJson(json: any): DeviceListReq {
    return new DeviceListReq({
      province: json['province'],
    });
  }

  toJson(): any {
    return {
      province: this.province,
    };
  }
}

export class DeviceDistribution {
  /**  设备类型 */
  deviceType: string;

  /**  设备数量 */
  deviceCount: number;

  constructor(data: Omit<DeviceDistribution, 'toJson'>) {
    this.deviceType = data.deviceType;
    this.deviceCount = data.deviceCount;
  }

  static fromJson(json: any): DeviceDistribution {
    return new DeviceDistribution({
      deviceType: json['device_type'],
      deviceCount: json['device_count'],
    });
  }

  toJson(): any {
    return {
      device_type: this.deviceType,
      device_count: this.deviceCount,
    };
  }
}

export class DeviceAttendanceResp {
  /**  近一个月设备出勤率 */
  lastMonthDeviceAttendance: string;

  /**  今日设备出勤率 */
  todayDeviceAttendance: string;

  /**  近一个月各设备类型的出勤率 */
  lastMonthDeviceTypeAttendance: DeviceTypeAttendance[];

  /**  今日各设备类型的出勤率 */
  todayDeviceTypeAttendance: DeviceTypeAttendance[];

  constructor(data: Omit<DeviceAttendanceResp, 'toJson'>) {
    this.lastMonthDeviceAttendance = data.lastMonthDeviceAttendance;
    this.todayDeviceAttendance = data.todayDeviceAttendance;
    this.lastMonthDeviceTypeAttendance = data.lastMonthDeviceTypeAttendance;
    this.todayDeviceTypeAttendance = data.todayDeviceTypeAttendance;
  }

  static fromJson(json: any): DeviceAttendanceResp {
    return new DeviceAttendanceResp({
      lastMonthDeviceAttendance: json['last_month_device_attendance'],
      todayDeviceAttendance: json['today_device_attendance'],
      lastMonthDeviceTypeAttendance:
        json['last_month_device_type_attendance'] != null
          ? (
              json['last_month_device_type_attendance'] as any[]
            ).map<DeviceTypeAttendance>((v: any) =>
              DeviceTypeAttendance.fromJson(v),
            )
          : [],
      todayDeviceTypeAttendance:
        json['today_device_type_attendance'] != null
          ? (
              json['today_device_type_attendance'] as any[]
            ).map<DeviceTypeAttendance>((v: any) =>
              DeviceTypeAttendance.fromJson(v),
            )
          : [],
    });
  }

  toJson(): any {
    return {
      last_month_device_attendance: this.lastMonthDeviceAttendance,
      today_device_attendance: this.todayDeviceAttendance,
      last_month_device_type_attendance: this.lastMonthDeviceTypeAttendance.map(
        (e: DeviceTypeAttendance) => e.toJson(),
      ),
      today_device_type_attendance: this.todayDeviceTypeAttendance.map(
        (e: DeviceTypeAttendance) => e.toJson(),
      ),
    };
  }
}

export class DeviceAttendanceReq {
  /**  省份 */
  province: string;

  constructor(data: Omit<DeviceAttendanceReq, 'toJson'>) {
    this.province = data.province;
  }

  static fromJson(json: any): DeviceAttendanceReq {
    return new DeviceAttendanceReq({
      province: json['province'],
    });
  }

  toJson(): any {
    return {
      province: this.province,
    };
  }
}

export class AreaOverviewResp {
  /**  设备数量 */
  deviceCount: number;

  /**  驾驶员数量 */
  driverCount: number;

  /**  设备分布 */
  deviceDistribution: DeviceDistribution[];

  constructor(data: Omit<AreaOverviewResp, 'toJson'>) {
    this.deviceCount = data.deviceCount;
    this.driverCount = data.driverCount;
    this.deviceDistribution = data.deviceDistribution;
  }

  static fromJson(json: any): AreaOverviewResp {
    return new AreaOverviewResp({
      deviceCount: json['device_count'],
      driverCount: json['driver_count'],
      deviceDistribution:
        json['device_distribution'] != null
          ? (json['device_distribution'] as any[]).map<DeviceDistribution>(
              (v: any) => DeviceDistribution.fromJson(v),
            )
          : [],
    });
  }

  toJson(): any {
    return {
      device_count: this.deviceCount,
      driver_count: this.driverCount,
      device_distribution: this.deviceDistribution.map(
        (e: DeviceDistribution) => e.toJson(),
      ),
    };
  }
}

export class AreaOverviewReq {
  /**  省份 */
  province: string;

  constructor(data: Omit<AreaOverviewReq, 'toJson'>) {
    this.province = data.province;
  }

  static fromJson(json: any): AreaOverviewReq {
    return new AreaOverviewReq({
      province: json['province'],
    });
  }

  toJson(): any {
    return {
      province: this.province,
    };
  }
}

export class AlarmListResp {
  /**  告警类型(ERROR:"故障",MAINTAIN:"保养到期",TEMPERATURE_WARNING:"温度异常",OIL_WARNING:"油耗异常",CONSTRUCTION_WARNING:"施工异常") */
  alarmType: string;

  /**  设备id */
  deviceId: number;

  /**  设备名 */
  deviceName: string;

  /**  描述信息 */
  description: string;

  /**  告警时间 */
  alarmTime: string;

  constructor(data: Omit<AlarmListResp, 'toJson'>) {
    this.alarmType = data.alarmType;
    this.deviceId = data.deviceId;
    this.deviceName = data.deviceName;
    this.description = data.description;
    this.alarmTime = data.alarmTime;
  }

  static fromJson(json: any): AlarmListResp {
    return new AlarmListResp({
      alarmType: json['alarm_type'],
      deviceId: json['device_id'],
      deviceName: json['device_name'],
      description: json['description'],
      alarmTime: json['alarm_time'],
    });
  }

  toJson(): any {
    return {
      alarm_type: this.alarmType,
      device_id: this.deviceId,
      device_name: this.deviceName,
      description: this.description,
      alarm_time: this.alarmTime,
    };
  }
}

export class AlarmListReq {
  /**  省份 */
  province?: string;

  constructor(data: Omit<AlarmListReq, 'toJson'>) {
    this.province = data.province;
  }

  static fromJson(json: any): AlarmListReq {
    return new AlarmListReq({
      province: json['province'],
    });
  }

  toJson(): any {
    return {
      province: this.province,
    };
  }
}
