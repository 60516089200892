// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import model_1 from '/mnt/builds/kJruUgmc/0/frontend/xt-web-monorepo/projects/xt-visual/src/models/areaView';
import model_2 from '/mnt/builds/kJruUgmc/0/frontend/xt-web-monorepo/projects/xt-visual/src/models/commonView';
import model_3 from '/mnt/builds/kJruUgmc/0/frontend/xt-web-monorepo/projects/xt-visual/src/models/deviceView';
import model_4 from '/mnt/builds/kJruUgmc/0/frontend/xt-web-monorepo/projects/xt-visual/src/models/global';
import model_5 from '/mnt/builds/kJruUgmc/0/frontend/xt-web-monorepo/projects/xt-visual/src/models/globalView';
import model_6 from '/mnt/builds/kJruUgmc/0/frontend/xt-web-monorepo/projects/xt-visual/src/models/projectView';

export const models = {
model_1: { namespace: 'areaView', model: model_1 },
model_2: { namespace: 'commonView', model: model_2 },
model_3: { namespace: 'deviceView', model: model_3 },
model_4: { namespace: 'global', model: model_4 },
model_5: { namespace: 'globalView', model: model_5 },
model_6: { namespace: 'projectView', model: model_6 },
} as const
