import {
  getV1CenterCommonViewAlarmList,
  getV1CenterCommonViewDeviceList,
  getV1CenterCommonViewLocation,
  getV1CenterCommonViewProjectList,
  getV1CenterCommonViewScheduleList,
} from '@/api/requests';

export default {
  name: 'commonView',
  state: {
    alarmList: [],
    deviceList: [],
    projectList: [],
    scheduleList: [],
    currentAddr: {},
  },
  reducers: {
    setAlarmList(state: any, { payload }: any) {
      return {
        ...state,
        alarmList: payload,
      };
    },
    setDeviceList(state: any, { payload }: any) {
      return {
        ...state,
        deviceList: payload,
      };
    },
    setProjectList(state: any, { payload }: any) {
      return {
        ...state,
        projectList: payload,
      };
    },
    setScheduleList(state: any, { payload }: any) {
      return {
        ...state,
        scheduleList: payload,
      };
    },
    setLocation(state: any, { payload }: any) {
      return {
        ...state,
        currentAddr: payload,
      };
    },
  },
  effects: {
    *getAlarmList({ payload }: any, { call, put }: any): any {
      console.log('getAlarmList payload', payload);
      const data = yield call(getV1CenterCommonViewAlarmList, payload);
      if (data) {
        yield put({
          type: 'setAlarmList',
          payload: data,
        });
      } else {
        console.log('getAlarmList error', data);
        return false;
      }
    },

    *getDeviceList({ payload }: any, { call, put }: any): any {
      const data = yield call(getV1CenterCommonViewDeviceList, payload);
      if (data) {
        yield put({
          type: 'setDeviceList',
          payload: data,
        });
      } else {
        console.log('getDeviceList error', data);
        return false;
      }
    },

    *getProjectList({ payload }: any, { call, put }: any): any {
      const data = yield call(getV1CenterCommonViewProjectList, payload);
      if (data) {
        yield put({
          type: 'setProjectList',
          payload: data,
        });
      } else {
        console.log('getProjectList error', data);
        return false;
      }
    },

    *getScheduleList({ payload }: any, { call, put }: any): any {
      const data = yield call(getV1CenterCommonViewScheduleList, payload);
      if (data) {
        yield put({
          type: 'setScheduleList',
          payload: data,
        });
      } else {
        console.log('getScheduleList error', data);
        return false;
      }
    },

    *getLocation({ payload }: any, { call, put }: any): any {
      const data = yield call(getV1CenterCommonViewLocation, payload);
      if (data) {
        yield put({
          type: 'setLocation',
          payload: data,
        });
      } else {
        console.log('getLocation error', data);
        return false;
      }
    },
  },
};
