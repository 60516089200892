import {
  getV1CenterProjectViewDevice,
  getV1CenterProjectViewProjectDetail,
  getV1CenterProjectViewProjectEvent,
} from '@/api/requests';

export default {
  name: 'projectView',
  state: {
    projectMessage: {},
    projectCanvasList: {
      workingHoursCurve: [],
      oilConsumptionCurve: [],
    },
    projectDeviceList: [],
    projectEventList: [],
    deviceTrajectory: [],
    weather: [],
  },
  effects: {
    *getProjectsPageData({ payload }: any, { call, put }: any): any {
      const projectDeviceList = yield call(
        getV1CenterProjectViewDevice,
        payload,
      );
      const projectMessage = yield call(
        getV1CenterProjectViewProjectDetail,
        payload,
      );
      const projectEventList = yield call(
        getV1CenterProjectViewProjectEvent,
        payload,
      );
      // const projectWorkCurve = yield call(
      //   getV1CenterProjectViewWorkCurve,
      //   payload,
      // );

      if (projectDeviceList) {
        yield put({
          type: 'setProjectPageData',
          payload: {
            projectEventList,
            projectDeviceList,
            // projectCanvasList: {
            //   workingHoursCurve: projectWorkCurve?.workingHoursCurve || [],
            //   oilConsumptionCurve: projectWorkCurve?.oilConsumptionCurve || [],
            // },
            projectMessage,
            // deviceTrajectory,
          },
        });
      } else {
        console.log('getProjectsPageData error');
        return false;
      }
    },
    *getWeather({ payload }: any, { call, put }: any): any {
      yield put({
        type: 'setWeather',
        payload: {
          payload,
        },
      });
    },
  },
  reducers: {
    setProjectPageData(state: any, { payload }: any) {
      return {
        ...state,
        ...payload,
      };
    },
    setWeather(state: any, { payload }: any) {
      return {
        ...state,
        weather: payload,
      };
    },
  },
};
