type IRequest = (url: string, opts?: any) => Promise<any>;
export class ProjectV2Req {
  private static _request: IRequest | undefined;
  static installHttp(request: any) {
    ProjectV2Req._request = request;
  }

  static get request() {
    if (!ProjectV2Req._request) {
      throw new Error('请先初始化 http： ProjectV2Req.installHttp');
    }
    return ProjectV2Req._request;
  }
}

interface Http {
  get: (url: string, params?: Record<string, any>) => Promise<any>;
  post: (
    url: string,
    data?: Record<string, any>,
    params?: Record<string, any>,
  ) => Promise<any>;
  put: (
    url: string,
    data?: Record<string, any>,
    params?: Record<string, any>,
  ) => Promise<any>;
  delete: (
    url: string,
    data?: Record<string, any>,
    params?: Record<string, any>,
  ) => Promise<any>;
  download: (
    url: string,
    params?: Record<string, any> | undefined,
  ) => Promise<any>;
  upload: (file: File) => Promise<any>;
}

function download(url: string, params?: Record<string, any>) {
  return ProjectV2Req.request(url, {
    responseType: 'blob',
    params,
  });
}

export const http = {
  get: (url: string, params?: any) => ProjectV2Req.request(url, { params }),
  post: (url: string, data?: Record<string, any>) =>
  ProjectV2Req.request(url, { data, method: 'POST' }),
  put: (url: string, data?: Record<string, any>) =>
  ProjectV2Req.request(url, { data, method: 'PUT' }),
  delete: (url: string, data?: Record<string, any>) =>
  ProjectV2Req.request(url, { method: 'DELETE', data }),
  download,
  upload: (file: File) => {
    const formData = new FormData();
    formData.append('file', file);
    return ProjectV2Req.request('/upload/file', {
      method: 'POST',
      data: formData,
    });
  },
};

export interface PageResp<T> {
  data: T[];
  page: number;
  size: number;
  total: number;
}
