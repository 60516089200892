import {
  getV1CenterGlobalViewNationalOverview,
  getV1CenterGlobalViewProvinceOverview,
} from '../api/requests';

export interface IProvinceOverView {
  province: string;
  projectCount: number;
  deviceCount: number;
}

export interface INationalOverview {
  projectCount: number;
  deviceCount: number;
  workHours: string;
}

export default {
  namespace: 'globalView',
  state: {
    nationalOverview: {} as INationalOverview,
    provinceOverview: [] as IProvinceOverView[],
  },
  reducers: {
    setNationalOverview(state: any, { payload }: any) {
      return {
        ...state,
        nationalOverview: payload as INationalOverview,
      };
    },

    setProvinceOverview(state: any, { payload }: any) {
      return {
        ...state,
        provinceOverview: payload as IProvinceOverView[],
      };
    },
  },
  effects: {
    *getNationalOverview({ payload }: any, { call, put }: any): any {
      const data = yield call(getV1CenterGlobalViewNationalOverview, payload);
      if (data) {
        yield put({
          type: 'setNationalOverview',
          payload: data,
        });
      } else {
        console.log('getNationalOverview error', data);
        return false;
      }
    },

    *getProvinceOverview({ payload }: any, { call, put }: any): any {
      const data = yield call(getV1CenterGlobalViewProvinceOverview, payload);
      if (data) {
        yield put({
          type: 'setProvinceOverview',
          payload: data,
        });
      } else {
        console.log('getProvinceOverview error', data);
        return false;
      }
    },
  },
  subscriptions: {},
};
