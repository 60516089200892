/*
 * @Author: zdd
 * @Date: 2023-12-27 10:35:03
 * @LastEditors: zdd dongdong@grizzlychina.com
 * @LastEditTime: 2024-01-16 16:25:01
 * @FilePath: global_socket.ts
 */
import { eventChange } from '@/components/GlobalSocketProvider/event';
import { useEffect } from 'react';
import { history, proxy, proxyMap, useSnapshot } from 'umi';
import { v4 as uuidv4 } from 'uuid';

export type WindowType = 'main' | 'region' | 'project' | 'device';
const CLIENT_ID = 'big_client_id';
const XT_VERSION = 'xt_version';
const TOKEN = 'token';
const WINDOW_TYPE = 'window_type';
const AREA_ID = 'region_id';
const PROJECT_ID = 'project_id';
const DEVICE_ID = 'device_id';

// 项目全局事件类型
export type projectEventStatus = {
  // 完工
  project_finish: 'project_finish';
  // 中止
  project_stop: 'project_stop';
  // 重启
  project_restart: 'project_restart';
};

// 运输设备全局事件类型
export type deviceEventStatus = {
  // 运输车到达
  transport_reach: 'transport_reach';
  // 运输车出发
  transport_trailer_go: 'transport_trailer_go';
  // 运输确认
  transport_confirm_way: 'transport_confirm_way';
};

// 订单全局事件类型
export type orderEventStatus = {
  // 订单创建
  new_order: 'new_order';
  // 订单确认
  confirm_order: 'confirm_order';
  // 订单取消
  cancel_order: 'cancel_order';
};

// 设备退场
export type deviceOutEventStatus = {
  // 设备退场
  device_out: 'device_out';
};

interface IGlobalState {
  windowType: WindowType;
  areaId?: string;
  projectId?: string;
  deviceId?: string;
}

const storageKey = {
  windowType: WINDOW_TYPE,
  areaId: AREA_ID,
  projectId: PROJECT_ID,
  deviceId: DEVICE_ID,
};

const storage = {
  get(key: string) {
    return localStorage.getItem(key);
  },
  set(key: string, value: string) {
    return localStorage.setItem(key, value);
  },
  remove(key: string) {
    return localStorage.removeItem(key);
  },
};

// 事件拼接
class SocketSingle {
  private static w: SocketSingle;
  private constructor() {}
  public static getInstance() {
    if (!this.w) SocketSingle.w = new SocketSingle();
    return SocketSingle.w;
  }

  static get instance() {
    return SocketSingle.getInstance();
  }

  private state = proxy<IGlobalState>({
    windowType: 'main',
    areaId: '1',
    projectId: '174',
    deviceId: '5',
  });

  static readonly baseUrl = `${WS_API}/ws`;
  static readonly versionsUpdate = 'screen/screen-versions/';
  static readonly windowChange = 'screen/screens/';

  static readonly intervalSec = 1000;

  // static readonly eventInfoNames = [
  //   'event-dispatch-exits/',
  //   'project/event-project-status/',
  //   'order/event-rent-orders-status/',
  //   'project/event-dispatches/',
  //   'transport/event-transport-status/',
  // ];

  static readonly tablePath = {
    /** 项目进度表 */
    projectTable: 'project/projects/?paging=f&interval-sec=10',
    /** 设备排期表 */
    scheduleTable: 'project/schedule-tables/?paging=f&interval-sec=10',
  };

  static get mainPath() {
    return {
      /** 设备地图数据（全国） */
      mapDevices: `device/map-infos/?interval-sec=${this.intervalSec}`,
      /** 查询统计信息（区域概况） */
      nationalStatistic: `device/statistic-national-infos/?paging=f&interval-sec=${this.intervalSec}`,
      /** 报警详情 */
      alarmData: `device/device-alarms/?interval-sec=${this.intervalSec}`,
      /** 设备列表 */
      deviceMapList: `device/map-infos/?interval-sec=${this.intervalSec}`,
    };
  }

  static get regionPath() {
    const areaId = this.instance.state.areaId;
    return {
      /** 设备每日出勤 */
      dailyAttendance: `device/daily_attendance/${areaId}?interval-sec=${this.intervalSec}`,
      /** 设备每月出勤 */
      monthAttendance: `device/month_attendance/${areaId}?interval-sec=${this.intervalSec}`,
      /** 查询统计信息（区域概况） */
      areaStatistic: `device/statistic-areal-infos/?paging=f&area_id=${areaId}&interval-sec=${this.intervalSec}`,
      /** 项目评价 */
      orderEvaluation: `order/rent-order-evaluation-score/${areaId}?interval-secinterval-sec=${this.intervalSec}`,
      /** 项目状态 */
      projectStatus: `project/project-map-infos/?interval-sec=${this.intervalSec}`,
      /** 设备列表 */
      // deviceMapList: `device/map-infos/?area_id=${areaId}&interval-sec=${this.intervalSec}`,
      deviceMapList: `device/map-infos/?interval-sec=${this.intervalSec}`,
      /** 调度列表 */
      // dispatchMapList: `project/dispatch-map-infos/?area_id=${areaId}&interval-sec=${this.intervalSec}`,
      dispatchMapList: `project/dispatch-map-infos/?interval-sec=${this.intervalSec}`,
      /** 项目列表 */
      // projectMapList: `project/project-map-infos/?area_id=${areaId}&interval-sec=${this.intervalSec}`,
      projectMapList: `project/project-map-infos/?interval-sec=${this.intervalSec}`,
      /** 设备进场调度 */
      deviceEnterDispatch: 'project/push-new-dispatch/',
      /** 设备退场调度 */
      deviceExitDispatch: 'project/push-new-dispatch-exit/',
    };
  }

  static get projectPath() {
    const projectId = this.instance.state.projectId;
    return {
      /** 项目事件 */
      projectLogs: `project/project-logs/?project_id=${projectId}&interval-sec=${this.intervalSec}`,
      /** 项目详情(主屏幕) */
      projectInfo: `project/screen/${projectId}?interval-sec=${this.intervalSec}`,
      /** 查询设备在项目中的每日统计信息（工时，油耗，油量） */
      projectStatistic: `device/statistic-device-projects/${projectId}?interval-sec=${this.intervalSec}`,
      /** 设备列表 */
      deviceMapList: `device/map-infos/?project_id=${projectId}&interval-sec=${this.intervalSec}`,
    };
  }

  static get devicePath() {
    const deviceId = this.instance.state.deviceId;
    return {
      /** 设备信息 */
      deviceInfo: `device/device-info/${deviceId}?interval-sec=${this.intervalSec}`,
      /** 查询设备每日统计信息（工时，油耗，油量） */
      deviceStatistic: `device/statistic-device-dailies/${deviceId}?interval-sec=${this.intervalSec}`,
      /** 设备监控信息 */
      deviceMonitors: `device/monitors/${deviceId}?interval-sec=${this.intervalSec}`,
      /** 设备故障列表 */
      deviceFaultList: `device/device-faults/?interval-sec=${this.intervalSec}`,
      /** 设备地图信息 */
      deviceMapInfo: `device/map-infos/${deviceId}?interval-sec=${this.intervalSec}`,
    };
  }

  static get eventInfoPath() {
    return [
      'project/event-dispatch-exits/',
      'project/event-project-status/',
      'order/event-rent-orders-status/',
      'project/event-dispatches/',
      'transport/event-transport-status/',
    ];
  }

  static get eventAlarmPath() {
    return ['hardware/event-alarms/'];
  }

  private static get contentNames() {
    const mainPath = this.mainPath;
    const regionPath = this.regionPath;
    const projectPath = this.projectPath;
    const devicePath = this.devicePath;
    return [
      ...Object.values(this.tablePath),
      ...Object.values(mainPath),
      ...Object.values(regionPath),
      ...Object.values(projectPath),
      ...Object.values(devicePath),
    ];
  }

  static config({ readyState, sendMessage }: any) {
    const windowType = SocketSingle.useWindowType();
    this.instance.sendMessage = sendMessage;

    useEffect(() => {
      for (const key in storageKey) {
        if (Object.prototype.hasOwnProperty.call(storageKey, key)) {
          const element = storageKey[key as keyof typeof storageKey],
            data = storage.get(element);
          if (data) {
            this.instance.state[key as keyof IGlobalState] = data as any;
          }
        }
      }
    }, []);

    useEffect(() => {
      if (readyState !== 1) return;
      this.sendMessage('clear');
      const pathname = history.location.pathname;
      /// 大屏
      if (pathname.includes('/big')) {
        let paths = this[`${windowType}Path`];
        [...this.eventInfoPath, ...this.eventAlarmPath].map((i) =>
          this.sendMessage(i),
        );
        if (paths) {
          Object.values(paths).forEach((path) => {
            this.sendMessage(path);
          });
        }

        return;
      }
      /// 大屏
      if (pathname.includes('/projectTable')) {
        this.sendMessage(this.tablePath.projectTable);
        return;
      }
      if (pathname.includes('/scheduleTable')) {
        this.sendMessage(this.tablePath.scheduleTable);
        return;
      }
    }, [this.instance.state, windowType, readyState]);
  }

  sendMessage?: (
    data: string | ArrayBufferLike | Blob | ArrayBufferView,
  ) => void;

  static get token() {
    const t = storage.get(TOKEN);
    return t || 'app:1:1:code';
  }

  static get uuid() {
    let id = storage.get(CLIENT_ID);
    if (id) return id;

    id = uuidv4();
    storage.set(CLIENT_ID, id);
    return id;
  }

  static sendMessage(path: string, action: 'subscribe' = 'subscribe') {
    if (!this.instance.sendMessage) throw new Error('未设置sendMessage');

    console.log('发送消息', path, action);

    const body = JSON.stringify({
      token: this.token,
      client_id: this.uuid,
      action,
      path,
    });
    const sendMessage = this.instance.sendMessage;

    sendMessage(body);
  }

  static onSocketMessage(e: any) {
    const data = JSON.parse(e.data);
    let result = {
      type: 'event-info',
      message: '',
    };
    if (!data.success) return;
    const pathname = history.location.pathname;

    if (this.contentNames.includes(data.path)) {
      if (
        data.path === 'project/push-new-dispatch/' ||
        data.path === 'project/push-new-dispatch-exit/'
      ) {
        setTimeout(() => {
          state.set(data.path, data);
        }, 3000);
      } else {
        state.set(data.path, data);
      }
    }

    /// 处理Info事件订阅
    if (
      data.path &&
      this.eventInfoPath.includes(data.path) &&
      pathname.includes('/big')
    ) {
      console.log('处理事件订阅');
      result.type = 'event-info';
      switch (data.path) {
        // 订单变动
        case 'order/event-rent-orders-status/':
          result.message = eventChange(
            data.data?.data?.event_type,
            data.data?.data?.name,
          );
          break;
        // 设备调度
        case 'project/event-dispatches/':
          result.message = eventChange(
            'device_dispatches',
            data.data?.data?.device_name,
            data.data?.data?.project_name,
          );
          break;
        // 设备退场
        case 'project/event-dispatch-exits/':
          result.message = eventChange(
            'device_exit',
            data.data?.data?.device_name,
          );
          break;
        // 项目变动
        case 'project/event-project-status/':
          result.message = eventChange(
            data.data?.data?.event_type,
            data.data?.data?.project_name,
          );
          break;
        // 运输变动
        case 'transport/event-transport-status/':
          result.message = eventChange(
            data.data?.data?.event_type,
            data.data?.data?.event_type === 'transport_confirm_way'
              ? data.data?.data?.item_name
              : data.data?.data?.trailer_name,
          );
          break;
      }
    }

    // 处理Warning/Error事件
    if (data.path === 'device/device-alarms/') {
      result.type = 'event-alarm';
      console.log('处理告警事件订阅', data.data);
      result.message = data.data.data.message;
    }

    /// 刷新页面 && URL跳转
    if (data.path === 'screen/screens/') {
      if (data.data.uri) {
        const obj = JSON.parse(data.data.data_string);
        if (obj.region) {
          const windowType = obj.region;
          this.updateState('windowType', obj.region);
          switch (windowType) {
            case 'region':
              this.updateState('areaId', obj.typeDetailId);
              Object.values(this.regionPath).forEach((path) => {
                this.sendMessage(path);
              });

              break;
            case 'project':
              this.updateState('projectId', obj.typeDetailId);
              Object.values(this.projectPath).forEach((path) => {
                this.sendMessage(path);
              });
              break;
            case 'device':
              this.updateState('deviceId', obj.typeDetailId);
              Object.values(this.devicePath).forEach((path) => {
                this.sendMessage(path);
              });
              break;
            default:
              break;
          }
        }
        if (
          !pathname.includes(data.data.uri) &&
          !pathname.includes('/terminal')
        ) {
          history.push(data.data.uri);
        }

        const localVersion = storage.get(XT_VERSION);
        const latestVersion = data.data.screen_version_id;
        // 如果版本不一致，刷新页面
        if (localVersion !== latestVersion.toString()) {
          storage.set(XT_VERSION, latestVersion);
          setTimeout(() => {
            location.reload();
          }, 3000);
        }
      }
    }

    // 更新token
    if (data.key === 'token-refresh') {
      storage.set(TOKEN, data.data);
    }

    return result;
  }

  static updateState(key: keyof IGlobalState, value: any) {
    this.instance.state[key] = value;
    storage.set(storageKey[key], value);
  }

  static useWindowType() {
    return useSnapshot(this.instance.state).windowType;
  }

  static useProxyContent(path: string) {
    try {
      if (!this.contentNames.includes(path)) return {};
      return useSnapshot(state).get(path) ?? {};
    } catch (error) {
      console.log(error);
      return {};
    }
  }
}

const state = proxyMap<string, any>();

const actions = {};

const socket = SocketSingle.instance;

export { state, actions, socket, SocketSingle };
